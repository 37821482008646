import Sobre from '../../assets/images/sobre.png'
// import ShieldI from '../../assets/icons/shield_i.svg'
// import ShieldPerson from '../../assets/icons/shield_person.svg'
import GoBackHeader from "../../components/GoBackHeader";
import {
  ButtonContainer,
  // Container
} from './styles';
import {
  useState,
  // useEffect,
  // useCallback,
} from "react";
import Loader from "../../components/Loader";
import Button from '../../components/Button';
import Pdf from '../../assets/TELEMBRO_TERMOS_DE_USO_E_NAVEGACAO_E_POLITICA_DE_PRIVACIDADE.pdf'

export default function About() {
  const [isLoading] = useState(false);

  return (
    <>
      <Loader isLoading={isLoading} />
      <GoBackHeader
        backAction={"/"}
        title={"Sobre"}
        mustShowInfoIcon={false}
      />
      <div className='basic-container'>
        <>
          <div className='image-container'>
            <img src={Sobre} alt='logo' />
          </div>

        </>
        <div className="regular-title">
          <br />
          <br />
          Termos de uso e navegação e Política de privacidade - TeLembro®
          <br />
          <br />
        </div>
        <div className="regular-text">
          O TeLembro é uma empresa de tecnologia e oferece serviços relacionados ao bem estar e agilidade para organizar seus compromissos.
          <br />
          <br />
          Clique no botão abaixo para ver nossos termos de uso.
          <br />
          <br />
          <br />
          <br />
        </div>


        <ButtonContainer>
          <Button
            type="button"
            onClick={(e) => {
              window.open(Pdf, '_new');
            }}

          >
            Ver termos de uso
          </Button>
        </ButtonContainer>
      </div>
    </>
  )
};