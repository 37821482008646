// import CalendarImage from '../../assets/images/new-layout/calendar_some_some.svg'
// import ShieldI from '../../assets/icons/shield_i.svg'
// import ShieldPerson from '../../assets/icons/shield_person.svg'
import GoBackHeader from "../../components/GoBackHeader";
import NoNotifications from '../../assets/images/no-notifications-image.svg';
import Bell from '../../assets/icons/bell.svg';
import Loader from "../../components/Loader";
import {
  useState,
  useEffect,
  useCallback,
} from 'react';
import AppointmentsService from '../../services/AppointmentsService';
import { localGet } from "../../lib/session";
import Config from '../../config/environments/local';
// import moment from 'moment';
import 'moment/locale/pt-br'; // Importa a localização para português do Brasil

export default function Notifications() {
  const [homeViewModel, setHomeViewModel] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [sessionObject] = useState(localGet(Config.SESSION_KEY));


  const listAppointments = useCallback(async () => {
    if (!sessionObject) {
      return null;
    }

    try {
      setIsLoading(true);

      const viewModel = await new AppointmentsService(Config.API_BASE_URL).GetNewHomeViewModel(
        {
          UserId: sessionObject.userId,
        }
      );

      setHomeViewModel(viewModel.Result);

    } catch (error) {
      console.log('error ', error);
    } finally {
      setIsLoading(false);
    }
  }, [sessionObject]);

  useEffect(() => {
    listAppointments();

    listAppointments();
  }, [listAppointments]);


  return (
    <>
      <GoBackHeader
        backAction={"/"}
        title={"Notificações"}
        mustShowInfoIcon={false}
      />
      <Loader isLoading={isLoading} />


      {
        (homeViewModel && homeViewModel.Notifications && homeViewModel.Notifications.length > 0) ? (
          <>
            <div className='basic-container no-padding'>
              <div className='appointments-notifications'>
                {homeViewModel.Notifications.map((notification) => (
                  <div className='appointment-notification'
                    data-id={notification.Id}
                    key={notification.Id}
                  >
                    <div className='notification-title'>
                      <img src={Bell} alt='profile' />
                    </div>
                    <div style={{
                      display: 'flex',
                      flexDirection: 'column',
                      gap: '4px',
                      width: '100%'
                    }}>
                      <div className='notification-title'>
                        {notification.Title}
                      </div>
                      <div className='notification-observation'>
                        {notification.Description}
                      </div>
                      <div className='notification-link'>
                        <a href={`/shared-messages/${notification.AppointmentId}`}>
                          Ver compromisso
                        </a>
                      </div>
                    </div>
                    {(notification.IsNew === true) && (
                      <>
                        <div className='today-text'>
                          Hoje!
                        </div>
                      </>
                    )}
                  </div>
                ))}
              </div>
            </div>
          </>
        ) : (
          <div className='basic-container'>
            <>
              <div className='image-container'>
                <img src={NoNotifications} alt='profile' />
              </div>
              <div className='no-content-text'>
                Você ainda não possui nenhuma notificação
              </div>
              <div className='no-appointments'>
                Ao criar compromissos as atualizações aparecerão aqui
                <a href='/new'>
                  Criar compromisso +
                </a>
              </div>
            </>
          </div>
        )
      }

    </>
  )
};