import AllAppointmentsReport from "../../components/AllAppointmentsReport"
import {
  Form,
} from "./styles";
import FormGroup from "../../components/FormGroup";
import Input from "../../components/Input";
import Select from "../../components/Select";
import Button from "../../components/Button";
import Loader from "../../components/Loader";
import {
  useState,
  useEffect,
  useCallback,
} from 'react';
import Config from "../../config/environments/local";
import AppointmentTypesService from '../../services/AppointmentTypesService';
import AppointmentCategoriesService from '../../services/AppointmentCategoriesService';
import { localGet } from "../../lib/session";
import { format } from 'date-fns';
import GoBackHeader from "../../components/GoBackHeader";

import "react-datepicker/dist/react-datepicker.css";
import
Calendar,
{ registerLocale }
  from "react-datepicker";
import ptBR from 'date-fns/locale/pt-BR';
registerLocale('pt-br', ptBR)

export default function AllAppointments() {
  const [sessionObject] = useState(localGet(Config.SESSION_KEY));

  const [isLoading, setIsLoading] = useState(false);
  const [appointmentTypeId, setAppointmentTypeId] = useState('');
  const [startDateDate, setStartDateDate] = useState('');
  const [endDateDate, setEndDateDate] = useState('');
  const [appointmentTypes, setAppointmentTypes] = useState([]);
  const [appointmentCategories, setAppointmentCategories] = useState([]);

  const loadAppointmentTypes = useCallback(async () => {
    try {
      setIsLoading(true);
      const appointmentTypesList = await new AppointmentTypesService(Config.API_BASE_URL).listAppointmentTypesByUserId(sessionObject.userId);
      setAppointmentTypes(appointmentTypesList.Result);
    } catch (error) {
      console.log('error ', error);
    } finally {
      setIsLoading(false);
    }
  }, [sessionObject.userId]);

  const loadAppointmentCategories = useCallback(async () => {
    try {
      setIsLoading(true);
      const appointmentCategoriesList = await new AppointmentCategoriesService(Config.API_BASE_URL).listAppointmentCategories();
      setAppointmentCategories(appointmentCategoriesList.Result);
    } catch (error) {
      console.log('error ', error);
    } finally {
      setIsLoading(false);
    }
  }, []);

  function filterAppointmentTypes(appointmentTypes, appointmentCategoryId) {
    const filteredAppointmentTypes = appointmentTypes.filter((appointmentType) => appointmentType.AppointmentCategoryId === appointmentCategoryId);

    return filteredAppointmentTypes ?? [];
  }

  function getStringDate(date) {
    if (!date) {
      return '';
    }

    return format(date, 'yyyy-MM-dd');
  }

  useEffect(() => {
    loadAppointmentTypes();
    loadAppointmentCategories();
  }, [
    loadAppointmentTypes,
    loadAppointmentCategories,
  ]);

  function handleChangeAppointmentType(e) {
    e.preventDefault();
    e.stopPropagation();

    setAppointmentTypeId(e.target.value);
  }

  function handleStartDateOnKeyPress(e) {
    let input = e.target;
    if (e.charCode < 47 || e.charCode > 57) {
      e.preventDefault();
    }
    var len = input.value.length;
    // If we're at a particular place, let the user type the slash
    if (len !== 1 || len !== 3) {
      if (e.charCode === 47) {
        e.preventDefault();
      }
    }

    if (e.key !== 'Backspace') {
      // If they don't add the slash, do it for them...
      if (len === 2) {
        input.value += '/';
      }

      // If they don't add the slash, do it for them...
      if (len === 5) {
        input.value += '/';
      }
    }
  }

  function handleEndDateOnKeyPress(e) {
    let input = e.target;
    if (e.charCode < 47 || e.charCode > 57) {
      e.preventDefault();
    }
    var len = input.value.length;
    // If we're at a particular place, let the user type the slash
    if (len !== 1 || len !== 3) {
      if (e.charCode === 47) {
        e.preventDefault();
      }
    }

    if (e.key !== 'Backspace') {
      // If they don't add the slash, do it for them...
      if (len === 2) {
        input.value += '/';
      }

      // If they don't add the slash, do it for them...
      if (len === 5) {
        input.value += '/';
      }
    }
  }

  return (
    <>
      <GoBackHeader
        backAction={"/"}
        title={"Relatórios"}
        mustShowInfoIcon={false}
      />
      <div className="basic-container">
        <div className="">
          <Form noValidate>
            <Loader isLoading={isLoading} />
            <div>
              <FormGroup>
                <div className='form-label'>Tipo de compromisso</div>
                <Select
                  value={appointmentTypeId}
                  onChange={handleChangeAppointmentType}
                  placeholder='Tipo de compromisso'
                >
                  <option value="">Todos</option>
                  {(
                    (appointmentCategories.length > 0) &&
                    (appointmentTypes.length > 0)) && (
                      appointmentCategories.map((appointmentCategory) => (
                        <>
                          <optgroup label={appointmentCategory.Name}></optgroup>
                          {
                            (filterAppointmentTypes(appointmentTypes, appointmentCategory.Id)).map((appointmentType) => (
                              <option value={appointmentType.Id}>{appointmentType.Name}</option>
                            ))
                          }
                        </>
                      ))
                    )}
                </Select>
              </FormGroup>
            </div>

            <div>
              <div className='regular-text-container'>
                <br />
                <br />
                <b>Período:</b>
              </div>
            </div>

            <div>
              {/* <FormGroup className='button-group'>
               
              </FormGroup> */}
              <FormGroup style={{ marginRight: '15px' }}>
                <div className='form-label'>Data inicial</div>
                {/* <div style={{ marginRight: '15px' }}> */}
                <div>
                  <Calendar
                    onChange={(date) => setStartDateDate(date)}
                    locale="pt-br"
                    dateFormat="dd/MM/yyyy"
                    selected={startDateDate}
                    placeholder='00/00/0000'
                    showYearDropdown
                    dropdownMode="select"
                    //yearDropdownItemNumber={50}
                    customInput={
                      <Input
                        type="text"
                        // value={startDateDate}
                        maxLength="10"
                        className="small"
                        placeholder='00/00/0000'
                        // onChange={handleChangeStartDate}
                        onKeyPress={handleStartDateOnKeyPress}
                        onKeyUp={handleStartDateOnKeyPress}
                      />
                    }
                  />

                  {/* <DatePicker
                      type="date"
                      locale="pt-br"
                      dateFormat="dd/MM/yyyy"
                      selected={startDate}
                      onChange={(date) => setStartDate(date)}
                      onKeyPress={handleStartDateOnKeyPress}
                    // onChange={handleChangeStartDate}
                    // value={startDate}
                    /> */}
                </div>
              </FormGroup>

            </div>

            <div>
              <FormGroup>
                <div className='form-label'>Data final</div>
                <div>
                  {/* <Input
                      type="text"
                      value={endDate}
                      maxLength="10"
                      placeholder='00/00/0000'
                      onChange={handleChangeEndDate}
                      onKeyPress={handleEndDateOnKeyPress}
                      onKeyUp={handleEndDateOnKeyPress}
                    /> */}
                  <Calendar
                    onChange={(date) => setEndDateDate(date)}
                    locale="pt-br"
                    dateFormat="dd/MM/yyyy"
                    selected={endDateDate}
                    placeholder='00/00/0000'
                    showYearDropdown
                    dropdownMode="select"
                    //yearDropdownItemNumber={50}
                    customInput={
                      <Input
                        type="text"
                        // value={endDateDate}
                        maxLength="10"
                        className="small"
                        placeholder='00/00/0000'
                        // onChange={handleChangeEndDate}
                        onKeyPress={handleEndDateOnKeyPress}
                        onKeyUp={handleEndDateOnKeyPress}
                      />
                    }
                  />
                </div>
              </FormGroup>
            </div>

            {/* <div>
              <FormGroup className='button-group'>
                <Button
                  type='button'
                  cancel
                  onClick={(e) => {
                    window.open('/all', '_self');
                  }}
                >
                  Limpar filtros
                </Button>
                <Button
                  type='button'
                  secondary
                  onClick={(e) => {
                    e.preventDefault();

                    const url = `${Config.KROONAR_PRINT_URL}?UserId=${sessionObject.userId}&Environment=${Config.ENVIRONMENT}&AppointmentTypeId=${appointmentTypeId}&StartDate=${getStringDate(startDateDate)}&EndDate=${getStringDate(endDateDate)}`

                    window.open(url, '_blank');
                  }}
                >
                  Gerar relatório
                </Button>
              </FormGroup>
            </div> */}

            <div className="floating-button-container">
              <FormGroup className='button-group'>
                {/* <Button
                  type='button'
                  cancel
                  onClick={(e) => {
                    window.open('/all', '_self');
                  }}
                >
                  Limpar filtros
                </Button> */}
                <Button
                  type='button'
                  secondary
                  onClick={(e) => {
                    e.preventDefault();

                    const url = `${Config.KROONAR_PRINT_URL}?UserId=${sessionObject.userId}&Environment=${Config.ENVIRONMENT}&AppointmentTypeId=${appointmentTypeId}&StartDate=${getStringDate(startDateDate)}&EndDate=${getStringDate(endDateDate)}`

                    window.open(url, '_blank');
                  }}
                >
                  Gerar relatório
                </Button>
              </FormGroup>
            </div>
          </Form >
        </div >

        <AllAppointmentsReport
          fetchOnlyActive={false}
          appointmentTypeId={appointmentTypeId}
          startDate={startDateDate}
          endDate={endDateDate}
        />
        <div style={{ height: '60px' }}></div>
      </div>
    </>
  )
};