import { Form, ButtonContainer } from "./styles";
import FormGroup from "../FormGroup";
import Input from "../../components/Input";
import TextArea from "../../components/TextArea";
// import RadioButton from "../../components/RadioButton";
// import RadioButtonLabel from "../../components/RadioButtonLabel";
import Button from "../../components/Button";
import Trash from '../../assets/icons/trash.svg';
import OrangeUpload from '../../assets/icons/orange-upload.svg';
import FileIcon from '../../assets/icons/file-icon.svg';
import AddParticipantForm from '../../assets/icons/add-participant-form.svg';
import CounterArrowUp from '../../assets/icons/counter-arrow-up.svg';
import CounterArrowDown from '../../assets/icons/counter-arrow-down.svg';
import OrangeTrash from '../../assets/icons/delete_attachment.svg';
import CalendarIcon from '../../assets/icons/calendar.svg';
import ClockIcon from '../../assets/icons/clock.svg';
import MoneyIcon from '../../assets/icons/money-icon.svg';
import BarcodeIcon from '../../assets/icons/barcode-icon.svg';
import InputAuto from '../InputAuto';
import { v4 as uuidv4 } from "uuid"; // Biblioteca para gerar IDs únicos
// import OrangeInfoButton from '../../assets/icons/menu/info-icon-orange.svg'
import SmallerToggleSwitch from "../SmallerToggleSwitch";
// import NumberInput from "../NumberInput";
// import PropTypes from 'prop-types';
import {
  useState,
  useEffect,
  useCallback,
} from "react";
import useErrors from "../../hooks/useErrors";
// import AppointmentCategoriesService from '../../services/AppointmentCategoriesService';
import AppointmentTypesService from '../../services/AppointmentTypesService';
import AppointmentRecurrenceTypesService from '../../services/AppointmentRecurrenceTypesService';
import AppointmentAdvanceReminderTypesService from '../../services/AppointmentAdvanceReminderTypesService';
import AppointmentsService from '../../services/AppointmentsService';
import SharedAppointmentsService from '../../services/SharedAppointmentsService';
import PushNotificationsService from "../../services/PushNotificationsService";
import Loader from '../Loader';
import Modal from '../Modal';
import ModalOptions from '../ModalOptions';
import isMatch from 'date-fns/isMatch'
import Config from "../../config/environments/local";
// import CurrencyInput from 'react-currency-input-field';
import MyCustomNumberFormat from '../MyCustomNumberFormat'
import CreatableSelect from 'react-select/creatable';
import Select from "../../components/Select";
import { localGet } from '../../lib/session';
import GoBackHeader from "../../components/GoBackHeader";

import "react-datepicker/dist/react-datepicker.css";
import
// DatePicker,
Calendar,
{ registerLocale }
  from "react-datepicker";
import ptBR from 'date-fns/locale/pt-BR';
registerLocale('pt-br', ptBR)

export default function AppointmentForm({
  buttonLabel,
  onSubmit,
  appointment,
  // onRemoveAttachment,
  appointmentCategoryId,
  viewerUserId,
}) {

  const formatDocumentValue = (numStr) => {
    if (!Number(numStr)) return "";
    return (numStr * 100);
  };

  // const getMustShowPushNotificationsActive = () => {
  //   console.log(pushNotificationsConfigurations.AllowInGeneral);
  //   console.log(pushNotificationsConfigurations.AllowForAllAppointments);
  //   console.log(appointment && appointment.MustSendPushNotification);

  //   return (
  //     (pushNotificationsConfigurations.AllowInGeneral) ||
  //     (pushNotificationsConfigurations.AllowForAllAppointments) ||
  //     (appointment && appointment.MustSendPushNotification)
  //   );
  // }

  const [sessionObject] = useState(localGet(Config.SESSION_KEY));
  // const [isPremiumUser] = useState(sessionObject.accessProfileId !== 2);
  // const [pushNotificationsConfigurations, setPushNotificationsConfigurations] = useState({});
  const [isPremiumUser] = useState(true);
  const [isUserAllowedMultipleAttachments] = useState(isPremiumUser);
  // const [tipTitle, setTipTitle] = useState('Informação');
  // const [tipBody, setTipBody] = useState('');
  const isSharedAppointment = ((viewerUserId && (appointment.Id !== 0)) && (appointment.UserId !== viewerUserId));
  const isFieldsDisabled = (isSharedAppointment);
  const [name, setName] = useState(appointment.Name);
  const [observations, setObservations] = useState(appointment.Observations);
  // const [observationsCharCounter, setObservationsCharCounter] = useState((appointment && appointment.Observations) ? appointment.Observations.length : 0);
  const [appointmentTypeId, setAppointmentTypeId] = useState(appointment.AppointmentTypeId);
  const [appointmentDate, setAppointmentDate] = useState(appointment.AppointmentDate);
  const [appointmentDateDate, setAppointmentDateDate] = useState(getAppointmentDate(appointmentDate));
  const [barCodeNumber, setBarCodeNumber] = useState(appointment.BarCodeNumber);
  const [documentValue, setDocumentValue] = useState(formatDocumentValue(appointment.DocumentValue));
  const [realDocumentValue, setRealDocumentValue] = useState(appointment.DocumentValue ?? 0);
  const [appointmentTime, setAppointmentTime] = useState(appointment.AppointmentTime);
  const [appointmentRecurrenceId] = useState(appointment.AppointmentRecurrenceId);
  const [appointmentRecurrenceTypeId, setAppointmentRecurrenceTypeId] = useState(appointment.AppointmentRecurrenceTypeId);

  const [appointmentRecurrenceEndDate, setAppointmentRecurrenceEndDate] = useState(appointment.AppointmentRecurrenceEndDate);
  const [appointmentRecurrenceEndDateDate, setAppointmentRecurrenceEndDateDate] = useState(getAppointmentDate(appointmentRecurrenceEndDate));;
  const [appointmentRecurrenceEndTime, setAppointmentRecurrenceEndTime] = useState(appointment.AppointmentRecurrenceEndTime);
  const [appointmentRecurrenceUnity, setAppointmentRecurrenceUnity] = useState(appointment.AppointmentRecurrenceUnity);

  const [appointmentAdvanceReminderUnit1, setAppointmentAdvanceReminderUnit1] = useState((appointment && appointment.AppointmentAdvanceReminders && appointment.AppointmentAdvanceReminders[0]) ? appointment.AppointmentAdvanceReminders[0].AppointmentAdvanceReminderUnit : null);
  const [appointmentAdvanceReminderType1, setAppointmentAdvanceReminderType1] = useState((appointment && appointment.AppointmentAdvanceReminders && appointment.AppointmentAdvanceReminders[0]) ? appointment.AppointmentAdvanceReminders[0].AppointmentAdvanceReminderTypeId : null);

  const [appointmentAdvanceReminderUnit2, setAppointmentAdvanceReminderUnit2] = useState((appointment && appointment.AppointmentAdvanceReminders && appointment.AppointmentAdvanceReminders[1]) ? appointment.AppointmentAdvanceReminders[1].AppointmentAdvanceReminderUnit : null);
  const [appointmentAdvanceReminderType2, setAppointmentAdvanceReminderType2] = useState((appointment && appointment.AppointmentAdvanceReminders && appointment.AppointmentAdvanceReminders[1]) ? appointment.AppointmentAdvanceReminders[1].AppointmentAdvanceReminderTypeId : null);

  const [appointmentAdvanceReminderUnit3, setAppointmentAdvanceReminderUnit3] = useState((appointment && appointment.AppointmentAdvanceReminders && appointment.AppointmentAdvanceReminders[2]) ? appointment.AppointmentAdvanceReminders[2].AppointmentAdvanceReminderUnit : null);
  const [appointmentAdvanceReminderType3, setAppointmentAdvanceReminderType3] = useState((appointment && appointment.AppointmentAdvanceReminders && appointment.AppointmentAdvanceReminders[2]) ? appointment.AppointmentAdvanceReminders[2].AppointmentAdvanceReminderTypeId : null);
  const [appointmentAdvanceReminderUnit4, setAppointmentAdvanceReminderUnit4] = useState((appointment && appointment.AppointmentAdvanceReminders && appointment.AppointmentAdvanceReminders[3]) ? appointment.AppointmentAdvanceReminders[3].AppointmentAdvanceReminderUnit : null);
  const [appointmentAdvanceReminderType4, setAppointmentAdvanceReminderType4] = useState((appointment && appointment.AppointmentAdvanceReminders && appointment.AppointmentAdvanceReminders[3]) ? appointment.AppointmentAdvanceReminders[3].AppointmentAdvanceReminderTypeId : null);
  const [appointmentAdvanceReminderUnit5, setAppointmentAdvanceReminderUnit5] = useState((appointment && appointment.AppointmentAdvanceReminders && appointment.AppointmentAdvanceReminders[4]) ? appointment.AppointmentAdvanceReminders[4].AppointmentAdvanceReminderUnit : null);
  const [appointmentAdvanceReminderType5, setAppointmentAdvanceReminderType5] = useState((appointment && appointment.AppointmentAdvanceReminders && appointment.AppointmentAdvanceReminders[4]) ? appointment.AppointmentAdvanceReminders[4].AppointmentAdvanceReminderTypeId : null);

  // const [hourAdvanceReminder, setHourAdvanceReminder] = useState(appointment.HourAdvanceReminder);
  const [hourAdvanceReminder] = useState(appointment.HourAdvanceReminder);
  // const [appointmentHasHourAdvanceReminder, setAppointmentHasHourAdvanceReminder] = useState((appointment && appointment.HourAdvanceReminder));
  // const [mustShowHourAdvanceReminderSwitch, setMustShowHourAdvanceReminderSwitch] = useState((appointment && appointment.AppointmentTime));

  const [appointmentHasPushNotifications, setAppointmentHasPushNotifications] = useState((appointment && appointment.MustSendPushNotification));
  const [appointmentHasRecurrence, setAppointmentHasRecurrence] = useState((appointment && appointment.AppointmentHasRecurrences));
  const [appointmentHasAdvanceReminder, setAppointmentHasAdvanceReminder] = useState((appointment && appointment.AppointmentHasAdvanceReminders));
  const [reminderDate1] = useState((appointment && appointment.ReminderDates ? appointment.ReminderDates[0] : null));
  // const [reminderDate1Date, setReminderDate1Date] = useState(getReminderDate1(reminderDate1));
  const [reminderDate2] = useState((appointment && appointment.ReminderDates ? appointment.ReminderDates[1] : null));
  // const [reminderDate2Date, setReminderDate2Date] = useState(getReminderDate2(reminderDate2));
  const [reminderDate3] = useState((appointment && appointment.ReminderDates ? appointment.ReminderDates[2] : null));
  const [appointmentHasAttachment, setAppointmentHasAttachment] = useState((appointment && (appointment.AppointmentFileUrls && appointment.AppointmentFileUrls.length > 0)));
  // const [reminderDate3Date, setReminderDate3Date] = useState(null);

  // const [showReminderDate2, setShowReminderDate2] = useState(reminderDate2 ?? false);
  // const [showReminderDate3, setShowReminderDate3] = useState(reminderDate3 ?? false);

  // const [showAdvanceReminder2, setShowAdvanceReminder2] = useState(appointmentAdvanceReminderUnit2 ?? false);
  // const [showAdvanceReminder3, setShowAdvanceReminder3] = useState(appointmentAdvanceReminderUnit3 ?? false);

  const [showAdvanceReminder2, setShowAdvanceReminder2] = useState(appointmentAdvanceReminderUnit2 ?? false);
  const [showAdvanceReminder3, setShowAdvanceReminder3] = useState(appointmentAdvanceReminderUnit3 ?? false);
  const [showAdvanceReminder4, setShowAdvanceReminder4] = useState(appointmentAdvanceReminderUnit4 ?? false);
  const [showAdvanceReminder5, setShowAdvanceReminder5] = useState(appointmentAdvanceReminderUnit5 ?? false);

  const createOption = (id, label) => (id ? {
    label,
    value: id,
  } : null);

  // const [showRecurrenceEndTime, setShowRecurrenceEndTime] = useState((appointment && appointment.AppointmentRecurrenceEndTime && ((appointmentCategoryId === '6' || appointmentCategoryId === 6))));
  const [showRecurrenceEndTime, setShowRecurrenceEndTime] = useState(
    (appointment && appointment.AppointmentRecurrenceEndTime
      // && ((appointmentCategoryId === '6' || appointmentCategoryId === 6))
    ));
  const [appointmentFiles, setAppointmentFiles] = useState(null);
  const [appointmentFiles2, setAppointmentFiles2] = useState(null);
  const [appointmentFiles3, setAppointmentFiles3] = useState(null);
  const [appointmentFiles4, setAppointmentFiles4] = useState(null);
  const [appointmentFiles5, setAppointmentFiles5] = useState(null);
  const [singleAttachmentLabel] = useState(isUserAllowedMultipleAttachments ? 'Incluir anexos' : 'Incluir anexo');
  // const [singleAttachmentSubLabel] = useState(isUserAllowedMultipleAttachments ? 'Selecione até 5 arquivos que farão parte deste lembrete, para auxiliá-lo.' : 'Selecione o arquivo que fará parte deste lembrete para auxiliá-lo.');

  const [initialSharedUsers] = useState(appointment.SharedUserEmails ? appointment.SharedUserEmails : []);
  const [isFirstLoad, setIsFirstLoad] = useState(true);
  const [sharedUsers, setSharedUsers] = useState([]);
  const [appointmentTypes, setAppointmentTypes] = useState([]);
  const [titles, setTitles] = useState([]);
  const [emails, setEmails] = useState([]);
  const [appointmentRecurrenceTypes, setAppointmentRecurrenceTypes] = useState([]);
  const [appointmentAdvanceReminderTypes, setAppointmentAdvanceReminderTypes] = useState([]);
  const [mustShowAttachmentFileInput, setMustShowAttachmentFileInput] = useState(false);
  const [mustShowAttachmentFileInput2, setMustShowAttachmentFileInput2] = useState(false);
  const [mustShowAttachmentFileInput3, setMustShowAttachmentFileInput3] = useState(false);
  const [mustShowAttachmentFileInput4, setMustShowAttachmentFileInput4] = useState(false);
  const [mustShowAttachmentFileInput5, setMustShowAttachmentFileInput5] = useState(false);

  const [attachmentFileToDelete, setAttachmentFileToDelete] = useState({});
  const [attachmentFileToDeletePosition, setAttachmentFileToDeletePosition] = useState(0);

  const [isLoading, setIsLoading] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);
  // const [isTipModalOpen, setIsTipModalOpen] = useState(false);
  const [isModalOptionsOpen, setIsModalOptionsOpen] = useState(false);
  const [isModalDeleteOpen, setIsModalDeleteOpen] = useState(false);
  // const [appointmentToDelete, setAppointmentToDelete] = useState(null);
  const [appointmentToDelete] = useState(null);
  const [appointmentTypeOption, setAppointmentTypeOption] = useState(createOption(appointment.AppointmentTypeId, appointment.AppointmentTypeName));

  const [showDocumentValueAndBarCodeNumber, setShowDocumentValueAndBarCodeNumber] = useState(appointment.DocumentValue ? true : false);
  // const mustShowDocumentValueAndBarCodeNumber = (appointmentCategoryId === '5' || appointmentCategoryId === 5);
  // const mustShowAppointmentTime = (appointmentCategoryId === '6' || appointmentCategoryId === 6);
  const mustShowAppointmentTime = (true);

  // const invalidReminderDateLesserThanMessage = 'Esta data não pode ser inferior ou igual à data atual e/ou igual à data do compromisso ou dos outros lembretes';
  // const invalidAppointmentDateLesserThanReminderDateMessage = 'Esta data não pode ser igual às datas dos lembretes';
  // const invalidReminderDateAfteAppointmentDateMessage = 'Esta data não pode ser igual ou superior à data do compromisso ou dos outros lembretes';
  const invalidDateLesserThanTodayMessage = 'Esta data não pode ser inferior ou igual à data atual';

  // const areRecurrenceFieldsDisabled = (appointment.Id);
  const areRecurrenceFieldsDisabled = (false);

  const {
    errors,
    setError,
    removeError,
    getErrorMessageByFieldName,
  } = useErrors();

  const isFormValid = function () {
    let formIsValid = (appointmentTypeId && (appointmentDate && appointmentDate.length === 10) && errors.length === 0) ?? false;

    // if (appointmentCategoryId === '5' || appointmentCategoryId === 5) {
    //   formIsValid = ((documentValue && documentValue.length !== 0 && parseFloat(documentValue) !== 0) && errors.length === 0) && formIsValid;
    // }

    if (appointmentHasRecurrence) {
      formIsValid = ((appointmentRecurrenceTypeId && appointmentRecurrenceEndDate && appointmentRecurrenceUnity) && errors.length === 0) && formIsValid;

      if (appointmentRecurrenceTypeId === '1' || appointmentRecurrenceTypeId === 1) {
        formIsValid = ((appointmentTime) && (appointmentRecurrenceEndTime) && errors.length === 0) && formIsValid;
      }
    }

    if (isFieldsDisabled) {
      formIsValid = !isFieldsDisabled;
    }

    return formIsValid;
  }

  async function handleCreateAppointmentType(inputValue) {
    setIsLoading(true);

    const newAppointmentType =
      await new AppointmentTypesService(Config.API_BASE_URL).addAppointmentType({
        Name: inputValue,
        AppointmentCategoryId: appointmentCategoryId,
        UserId: viewerUserId,
      });

    if (newAppointmentType.Result) {
      const createdOption = createOption(newAppointmentType.Result.Id, newAppointmentType.Result.Name);

      setAppointmentTypes((prev) => [...prev, newAppointmentType.Result]);
      setAppointmentTypeOption(createdOption);
      setAppointmentTypeId(createdOption.value);
      setIsLoading(false);
      removeError('appointmentTypeId');
    } else if (newAppointmentType.Error) {
      setError({ field: 'appointmentTypeId', message: 'Opção já existe' });
    }
  };

  if (!appointment.AppointmentFile && !mustShowAttachmentFileInput) {
    setMustShowAttachmentFileInput(true);
  }

  if (!appointment.AppointmentFile2 && !mustShowAttachmentFileInput2) {
    setMustShowAttachmentFileInput2(true);
  }

  if (!appointment.AppointmentFile3 && !mustShowAttachmentFileInput3) {
    setMustShowAttachmentFileInput3(true);
  }

  if (!appointment.AppointmentFile4 && !mustShowAttachmentFileInput4) {
    setMustShowAttachmentFileInput4(true);
  }

  if (!appointment.AppointmentFile5 && !mustShowAttachmentFileInput5) {
    setMustShowAttachmentFileInput5(true);
  }

  function getAppointmentDate(appointmentDate) {
    if (appointmentDate && (!appointmentDate.includes('/aaaa'))) {
      const [day, month, year] = appointmentDate.split('/');
      const date = new Date(+year, month - 1, +day);

      if (date) {
        return date;
      } else {
        return null;
      }
    } else {
      return null;
    }
  }

  const loadAppointmentTypes = useCallback(async () => {
    try {
      setIsLoading(true);
      const appointmentTypesList = await new AppointmentTypesService(Config.API_BASE_URL).listAppointmentTypesByUserId(viewerUserId);
      setAppointmentTypes(appointmentTypesList.Result);
    } catch (error) {
      console.log('error ', error);
    } finally {
      setIsLoading(false);
    }
  }, [viewerUserId]);

  useEffect(() => {
    async function getAppointmentTitlesByUserId() {
      const existingTitles = await new AppointmentsService(Config.API_BASE_URL).GetAppointmentTitlesByUserId(sessionObject.userId);

      // console.log(getSharedWithEmails);

      if (existingTitles.Error) {
        alert(existingTitles.Error.Message);
        console.log('Ocorreu um erro ao recuperar nomes de compromissos');
      }
      else {
        // console.log(getSharedWithEmails.Result);
        setTitles(existingTitles.Result);
      }
    };
    getAppointmentTitlesByUserId();
  }, [sessionObject.userId]);

  useEffect(() => {
    async function getSharedWithEmails(e) {

      const getSharedWithEmails = await new SharedAppointmentsService(Config.API_BASE_URL).getSharedWithEmails(sessionObject.userId);

      // console.log(getSharedWithEmails);

      if (getSharedWithEmails.Error) {
        alert(getSharedWithEmails.Error.Message);
        console.log('Ocorreu um erro ao recuperar e-mails');
      }
      else {
        // console.log(getSharedWithEmails.Result);
        setEmails(getSharedWithEmails.Result);
      }
    }

    getSharedWithEmails();
  }, [sessionObject.userId]);

  useEffect(() => {
    loadAppointmentTypes();

    async function getPushNotificationsConfigurations() {
      try {
        setIsLoading(true);
        const getConfigurationsResponse = await new PushNotificationsService(Config.API_BASE_URL).GetConfigurations({
          UserId: sessionObject.userId,
        });

        // setPushNotificationsConfigurations(getConfigurationsResponse.Result);

        if (!appointment ||
          appointment.MustSendPushNotification !== false) {
          setAppointmentHasPushNotifications(getConfigurationsResponse.Result.AllowForAllAppointments);
        }

      } catch (error) {
        console.log('error ', error);
      } finally {
        setIsLoading(false);
      }
    }

    async function loadAppointmentRecurrenceTypes() {
      try {
        setIsLoading(true);
        const appointmentRecurrenceTypesList = await new AppointmentRecurrenceTypesService(Config.API_BASE_URL).listAppointmentRecurrenceTypes();
        setAppointmentRecurrenceTypes(appointmentRecurrenceTypesList.Result);
      } catch (error) {
        console.log('error ', error);
      } finally {
        setIsLoading(false);
      }
    }

    async function loadAppointmentAdvanceReminderTypes() {
      try {
        setIsLoading(true);
        const appointmentAdvanceReminderTypesList = await new AppointmentAdvanceReminderTypesService(Config.API_BASE_URL).listAppointmentAdvanceReminderTypes();
        setAppointmentAdvanceReminderTypes(appointmentAdvanceReminderTypesList.Result);
      } catch (error) {
        console.log('error ', error);
      } finally {
        setIsLoading(false);
      }
    }

    loadAppointmentRecurrenceTypes();
    loadAppointmentAdvanceReminderTypes();
    getPushNotificationsConfigurations();
  }, [appointment, loadAppointmentTypes, sessionObject.userId]);

  // Função para adicionar um novo sharedUser
  const addSharedUser = useCallback(async () => {
    setSharedUsers([
      ...sharedUsers,
      { id: uuidv4(), email: "" },
    ]);
  }, [sharedUsers]);

  // Função para remover um sharedUser
  const removeSharedUser = (id) => {
    setSharedUsers(sharedUsers.filter((user) => user.id !== id));
  };

  // Função para atualizar o email de um sharedUser
  const updateSharedUserEmail = (id, email) => {
    setSharedUsers(
      sharedUsers.map((user) =>
        user.id === id ? { ...user, email } : user
      )
    );
  };

  // Inicializar sharedUsers com dados existentes (modo edição)
  useEffect(() => {
    if (initialSharedUsers.length > 0 && isFirstLoad) {

      const usersWithId = initialSharedUsers.map((email) => ({
        id: uuidv4(),
        email: email,
      }));

      setSharedUsers(usersWithId);

      setIsFirstLoad(false);

    } else {
      if (sharedUsers.length === 0) {
        // Inicializar com um campo vazio
        addSharedUser();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addSharedUser, initialSharedUsers]);

  async function handleRemoveApproval() {
    try {
      setIsLoading(true);
      const removeApprovalResponse = await new SharedAppointmentsService(Config.API_BASE_URL).removeApproval({
        AppointmentId: appointment.Id,
        SharedUserId: viewerUserId,
        IsAccepted: false,
      });

      console.log(removeApprovalResponse);
      if (removeApprovalResponse.Result === true) {
        window.open('/', '_self');
      }
      else {
        alert('Ocorreu um erro ao sair do compartilhamento do compromisso.');
      }

    } catch (error) {
      console.log('error ', error);
      alert('Ocorreu um erro ao sair do compartilhamento do compromisso.');
    } finally {
      setIsLoading(false);
    }
  }

  function handleSubmit(e) {
    e.preventDefault();
    e.stopPropagation();

    if (showDocumentValueAndBarCodeNumber) {
      if ((!documentValue) || (documentValue.length === 0)) {
        setError({ field: 'documentValue', message: 'O valor é obrigatório' });
        return false;
      }
      else {
        removeError('documentValue');
      }
    }


    setIsLoading(true);

    // const emails = sharedUsers.map((user) => user.email.trim()).filter(email => email !== "");

    const emails = sharedUsers.map(user => user.email.trim()).filter(email => email !== "").join(',');


    onSubmit({
      name,
      observations,
      appointmentFiles,
      appointmentFiles2,
      appointmentFiles3,
      appointmentFiles4,
      appointmentFiles5,
      appointmentDate,
      appointmentCategoryId,
      appointmentTypeId,
      barCodeNumber,
      realDocumentValue,
      appointmentTime,
      reminderDate1,
      reminderDate2,
      reminderDate3,
      appointmentRecurrenceId,
      appointmentRecurrenceTypeId,
      appointmentRecurrenceEndDate,
      appointmentRecurrenceEndTime,
      appointmentRecurrenceUnity,
      hourAdvanceReminder,
      appointmentAdvanceReminderUnit1,
      appointmentAdvanceReminderType1,
      appointmentAdvanceReminderUnit2,
      appointmentAdvanceReminderType2,
      appointmentAdvanceReminderUnit3,
      appointmentAdvanceReminderType3,
      appointmentAdvanceReminderUnit4,
      appointmentAdvanceReminderType4,
      appointmentAdvanceReminderUnit5,
      appointmentAdvanceReminderType5,
      appointmentHasPushNotifications,
      emails
    });

  }

  function handleChangeNameAutoTitle(value) {
    setName(value);
  }

  // function handleChangeName(e) {
  //   setName(e.target.value);

  //   // if (!e.target.value) {
  //   //   setError({ field: 'Name', message: 'O nome é obrigatório' });
  //   // }
  //   // else {
  //   //   removeError('name');
  //   // }
  // }

  function handleChangeObservations(e) {

    if (e.target.value.length <= 300) {
      setObservations(e.target.value);
      // setObservationsCharCounter(e.target.value.length);
    }

    // if (!e.target.value) {
    //   setError({ field: 'observations', message: 'A observação é obrigatória' });
    // }
    // else {
    //   removeError('observations');
    // }
  }

  function handleChangeAppointmentRecurrenceTypeId(e) {
    setAppointmentRecurrenceTypeId(e.target.value);

    if (!e.target.value) {
      setError({ field: 'appointmentRecurrenceTypeId', message: 'O tipo de recorrência é obrigatório' });
      setShowRecurrenceEndTime(false);
    }
    else {
      removeError('appointmentRecurrenceTypeId');

      if (
        (e.target.value === 1 || e.target.value === '1') ||
        (e.target.value === 6 || e.target.value === '6')
      ) {
        setShowRecurrenceEndTime(true);
      }
      else {
        setShowRecurrenceEndTime(false);
      }
    }
  }

  function handleChangeAppointmentAdvanceReminderType1(e) {
    setAppointmentAdvanceReminderType1(e.target.value);

    if (!e.target.value) {
      setError({ field: 'appointmentAdvanceReminderType1', message: 'O tipo é obrigatório' });
    }
    else {
      removeError('appointmentAdvanceReminderType1');

      if (appointmentAdvanceReminderUnit1) {
        setShowAdvanceReminder2(true);
      }
      else {
        setShowAdvanceReminder2(false);
      }
    }
  }

  function handleChangeAppointmentAdvanceReminderType2(e) {
    setAppointmentAdvanceReminderType2(e.target.value);

    if (!e.target.value) {
      setError({ field: 'appointmentAdvanceReminderType2', message: 'O tipo é obrigatório' });
    }
    else {
      removeError('appointmentAdvanceReminderType2');

      if (appointmentAdvanceReminderUnit2) {
        setShowAdvanceReminder3(true);
      }
      else {
        setShowAdvanceReminder3(false);
      }
    }
  }

  function handleChangeAppointmentAdvanceReminderType3(e) {
    setAppointmentAdvanceReminderType3(e.target.value);

    if (!e.target.value) {
      setError({ field: 'appointmentAdvanceReminderType3', message: 'O tipo é obrigatório' });
    }
    else {
      removeError('appointmentAdvanceReminderType3');

      if (appointmentAdvanceReminderUnit3) {
        setShowAdvanceReminder4(true);
      }
      else {
        setShowAdvanceReminder4(false);
      }
    }
  }

  function handleChangeAppointmentAdvanceReminderType4(e) {
    setAppointmentAdvanceReminderType4(e.target.value);

    if (!e.target.value) {
      setError({ field: 'appointmentAdvanceReminderType4', message: 'O tipo é obrigatório' });
    }
    else {
      removeError('appointmentAdvanceReminderType4');

      if (appointmentAdvanceReminderUnit4) {
        setShowAdvanceReminder5(true);
      }
      else {
        setShowAdvanceReminder5(false);
      }
    }
  }

  function handleChangeAppointmentAdvanceReminderType5(e) {
    setAppointmentAdvanceReminderType5(e.target.value);

    if (!e.target.value) {
      setError({ field: 'appointmentAdvanceReminderType5', message: 'O tipo é obrigatório' });
    }
    else {
      removeError('appointmentAdvanceReminderType5');
    }
  }

  function incrementCount(e) {
    setAppointmentRecurrenceUnity((appointmentRecurrenceUnity ? parseInt(appointmentRecurrenceUnity) + 1 : 1));
    e.target.value = (appointmentRecurrenceUnity ? parseInt(appointmentRecurrenceUnity) + 1 : 1);
    handleChangeAppointmentRecurrenceUnity(e)
  }
  function decrementCount(e) {
    let valueToSet = (appointmentRecurrenceUnity ? parseInt(appointmentRecurrenceUnity) - 1 : 1);
    if (valueToSet < 1) {
      valueToSet = 1;
    }
    setAppointmentRecurrenceUnity(valueToSet);
    e.target.value = valueToSet;
    handleChangeAppointmentAdvanceReminderUnit1(e)
  }

  function handleDeleteAdvanceReminder1(e) {
    setAppointmentAdvanceReminderUnit1('');
    setAppointmentAdvanceReminderType1('');
    setShowAdvanceReminder2(false);
    removeError('appointmentAdvanceReminderUnit1');
    removeError('appointmentAdvanceReminderType1');
  }

  function handleDeleteAdvanceReminder2(e) {
    setAppointmentAdvanceReminderUnit2('');
    setAppointmentAdvanceReminderType2('');
    setShowAdvanceReminder2(false);
    removeError('appointmentAdvanceReminderUnit2');
    removeError('appointmentAdvanceReminderType2');
  }

  function handleDeleteAdvanceReminder3(e) {
    setAppointmentAdvanceReminderUnit3('');
    setAppointmentAdvanceReminderType3('');
    setShowAdvanceReminder3(false);
    removeError('appointmentAdvanceReminderUnit3');
    removeError('appointmentAdvanceReminderType3');
  }
  function handleDeleteAdvanceReminder4(e) {
    setAppointmentAdvanceReminderUnit4('');
    setAppointmentAdvanceReminderType4('');
    setShowAdvanceReminder4(false);
    removeError('appointmentAdvanceReminderUnit4');
    removeError('appointmentAdvanceReminderType4');
  }
  function handleDeleteAdvanceReminder5(e) {
    setAppointmentAdvanceReminderUnit5('');
    setAppointmentAdvanceReminderType5('');
    setShowAdvanceReminder5(false);
    removeError('appointmentAdvanceReminderUnit5');
    removeError('appointmentAdvanceReminderType5');
  }

  function incrementAdvanceReminderCount1(e) {
    setAppointmentAdvanceReminderUnit1((appointmentAdvanceReminderUnit1 ? parseInt(appointmentAdvanceReminderUnit1) + 1 : 1));
    e.target.value = (appointmentAdvanceReminderUnit1 ? parseInt(appointmentAdvanceReminderUnit1) + 1 : 1);
    handleChangeAppointmentAdvanceReminderUnit1(e)
  }

  function incrementAdvanceReminderCount2(e) {
    setAppointmentAdvanceReminderUnit2((appointmentAdvanceReminderUnit2 ? parseInt(appointmentAdvanceReminderUnit2) + 1 : 1));
    e.target.value = (appointmentAdvanceReminderUnit2 ? parseInt(appointmentAdvanceReminderUnit2) + 1 : 1);
    handleChangeAppointmentAdvanceReminderUnit2(e)
  }

  function incrementAdvanceReminderCount3(e) {
    setAppointmentAdvanceReminderUnit3((appointmentAdvanceReminderUnit3 ? parseInt(appointmentAdvanceReminderUnit3) + 1 : 1));
    e.target.value = (appointmentAdvanceReminderUnit3 ? parseInt(appointmentAdvanceReminderUnit3) + 1 : 1);
    handleChangeAppointmentAdvanceReminderUnit3(e)
  }
  function incrementAdvanceReminderCount4(e) {
    setAppointmentAdvanceReminderUnit4((appointmentAdvanceReminderUnit4 ? parseInt(appointmentAdvanceReminderUnit4) + 1 : 1));
    e.target.value = (appointmentAdvanceReminderUnit4 ? parseInt(appointmentAdvanceReminderUnit4) + 1 : 1);
    handleChangeAppointmentAdvanceReminderUnit4(e)
  }
  function incrementAdvanceReminderCount5(e) {
    setAppointmentAdvanceReminderUnit5((appointmentAdvanceReminderUnit5 ? parseInt(appointmentAdvanceReminderUnit5) + 1 : 1));
    e.target.value = (appointmentAdvanceReminderUnit5 ? parseInt(appointmentAdvanceReminderUnit5) + 1 : 1);
    handleChangeAppointmentAdvanceReminderUnit5(e)
  }

  function decrementAdvanceReminderCount1(e) {
    let valueToSet = (appointmentAdvanceReminderUnit1 ? parseInt(appointmentAdvanceReminderUnit1) - 1 : 1);
    if (valueToSet < 1) {
      valueToSet = 1;
    }
    setAppointmentAdvanceReminderUnit1(valueToSet);
    e.target.value = valueToSet;
    handleChangeAppointmentAdvanceReminderUnit1(e)
  }

  function decrementAdvanceReminderCount2(e) {
    let valueToSet = (appointmentAdvanceReminderUnit2 ? parseInt(appointmentAdvanceReminderUnit2) - 1 : 1);
    if (valueToSet < 1) {
      valueToSet = 1;
    }
    setAppointmentAdvanceReminderUnit2(valueToSet);
    e.target.value = valueToSet;
    handleChangeAppointmentAdvanceReminderUnit2(e)
  }

  function decrementAdvanceReminderCount3(e) {
    let valueToSet = (appointmentAdvanceReminderUnit3 ? parseInt(appointmentAdvanceReminderUnit3) - 1 : 1);
    if (valueToSet < 1) {
      valueToSet = 1;
    }
    setAppointmentAdvanceReminderUnit3(valueToSet);
    e.target.value = valueToSet;
    handleChangeAppointmentAdvanceReminderUnit3(e)
  }

  function decrementAdvanceReminderCount4(e) {
    let valueToSet = (appointmentAdvanceReminderUnit4 ? parseInt(appointmentAdvanceReminderUnit4) - 1 : 1);
    if (valueToSet < 1) {
      valueToSet = 1;
    }
    setAppointmentAdvanceReminderUnit4(valueToSet);
    e.target.value = valueToSet;
    handleChangeAppointmentAdvanceReminderUnit4(e)
  }

  function decrementAdvanceReminderCount5(e) {
    let valueToSet = (appointmentAdvanceReminderUnit5 ? parseInt(appointmentAdvanceReminderUnit5) - 1 : 1);
    if (valueToSet < 1) {
      valueToSet = 1;
    }
    setAppointmentAdvanceReminderUnit5(valueToSet);
    e.target.value = valueToSet;
    handleChangeAppointmentAdvanceReminderUnit5(e)
  }

  function handleChangeAppointmentAdvanceReminderUnit1(e) {
    setAppointmentAdvanceReminderUnit1(e.target.value);

    if (!e.target.value) {
      setError({ field: 'advanceReminderUnit1', message: 'A quantidade é obrigatóra' });
    }
    else {
      removeError('advanceReminderUnit1');

      if (appointmentAdvanceReminderType1) {
        setShowAdvanceReminder2(true);
      }
      else {
        setShowAdvanceReminder2(false);
      }
    }
  }

  function handleChangeAppointmentAdvanceReminderUnit2(e) {
    setAppointmentAdvanceReminderUnit2(e.target.value);

    if (!e.target.value) {
      setError({ field: 'advanceReminderUnit2', message: 'A quantidade é obrigatóra' });
    }
    else {
      removeError('advanceReminderUnit2');

      if (appointmentAdvanceReminderType2) {
        setShowAdvanceReminder3(true);
      }
      else {
        setShowAdvanceReminder3(false);
      }
    }
  }

  function handleChangeAppointmentAdvanceReminderUnit3(e) {
    setAppointmentAdvanceReminderUnit3(e.target.value);

    if (!e.target.value) {
      setError({ field: 'advanceReminderUnit3', message: 'A quantidade é obrigatóra' });
    }
    else {
      removeError('advanceReminderUnit3');
    }
  }
  function handleChangeAppointmentAdvanceReminderUnit4(e) {
    setAppointmentAdvanceReminderUnit3(e.target.value);

    if (!e.target.value) {
      setError({ field: 'advanceReminderUnit4', message: 'A quantidade é obrigatóra' });
    }
    else {
      removeError('advanceReminderUnit4');
    }
  }
  function handleChangeAppointmentAdvanceReminderUnit5(e) {
    setAppointmentAdvanceReminderUnit3(e.target.value);

    if (!e.target.value) {
      setError({ field: 'advanceReminderUnit5', message: 'A quantidade é obrigatóra' });
    }
    else {
      removeError('advanceReminderUnit5');
    }
  }

  function handleChangeAppointmentRecurrenceUnity(e) {
    setAppointmentRecurrenceUnity(e.target.value);

    if (!e.target.value) {
      setError({ field: 'appointmentRecurrenceUnity', message: 'A quantidade da recorrência é obrigatóra' });
    }
    else {
      removeError('appointmentRecurrenceUnity');
    }
  }

  function handleAppointmentRecurrenceUnityOnKeyPress(e) {
    // let input = e.target;
    if (e.charCode < 47 || e.charCode > 57) {
      e.preventDefault();
    }

    // if (!input.value) {
    //   e.target.value = 1;
    //   setAppointmentRecurrenceUnity(1);
    //   handleChangeAppointmentRecurrenceUnity(e);
    // }
  }

  function handleAppointmentAdvanceReminderUnit1OnKeyPress(e) {
    // let input = e.target;
    if (e.charCode < 47 || e.charCode > 57) {
      e.preventDefault();
    }

    // if (!input.value) {
    //   e.target.value = 1;
    //   setAppointmentAdvanceReminderUnit1(1);
    //   handleChangeAppointmentAdvanceReminderUnit1(e);
    // }
  }

  function handleAppointmentAdvanceReminderUnit2OnKeyPress(e) {
    // let input = e.target;
    if (e.charCode < 47 || e.charCode > 57) {
      e.preventDefault();
    }

    // if (!input.value) {
    //   e.target.value = 1;
    //   setAppointmentAdvanceReminderUnit2(1);
    //   handleChangeAppointmentAdvanceReminderUnit2(e);
    // }
  }

  function handleAppointmentAdvanceReminderUnit3OnKeyPress(e) {
    // let input = e.target;
    if (e.charCode < 47 || e.charCode > 57) {
      e.preventDefault();
    }

    // if (!input.value) {
    //   e.target.value = 1;
    //   setAppointmentAdvanceReminderUnit3(1);
    //   handleChangeAppointmentAdvanceReminderUnit3(e);
    // }
  }

  function handleAppointmentAdvanceReminderUnit4OnKeyPress(e) {
    // let input = e.target;
    if (e.charCode < 47 || e.charCode > 57) {
      e.preventDefault();
    }

    // if (!input.value) {
    //   e.target.value = 1;
    //   setAppointmentAdvanceReminderUnit4(1);
    //   handleChangeAppointmentAdvanceReminderUnit4(e);
    // }
  }

  function handleAppointmentAdvanceReminderUnit5OnKeyPress(e) {
    // let input = e.target;
    if (e.charCode < 47 || e.charCode > 57) {
      e.preventDefault();
    }

    // if (!input.value) {
    //   e.target.value = 1;
    //   setAppointmentAdvanceReminderUnit5(1);
    //   handleChangeAppointmentAdvanceReminderUnit5(e);
    // }
  }

  // function incrementCount2(e) {
  //   setHourAdvanceReminder((hourAdvanceReminder ? parseInt(hourAdvanceReminder) + 1 : 1));
  //   e.target.value = (hourAdvanceReminder ? parseInt(hourAdvanceReminder) + 1 : 1);
  //   handleChangeHourAdvanceReminder(e)
  // }
  // function decrementCount2(e) {
  //   let valueToSet = (hourAdvanceReminder ? parseInt(hourAdvanceReminder) - 1 : 1);
  //   if (valueToSet < 1) {
  //     valueToSet = 1;
  //   }
  //   setHourAdvanceReminder(valueToSet);
  //   e.target.value = valueToSet;
  //   handleChangeHourAdvanceReminder(e)
  // }

  // function handleChangeHourAdvanceReminder(e) {
  //   setHourAdvanceReminder(e.target.value);

  //   if (!e.target.value) {
  //     setError({ field: 'hourAdvanceReminder', message: 'A quantidade da recorrência é obrigatóra' });
  //   }
  //   else {
  //     removeError('hourAdvanceReminder');
  //   }
  // }

  // function handleHourAdvanceReminderOnKeyPress(e) {
  //   let input = e.target;
  //   if (e.charCode < 47 || e.charCode > 57) {
  //     e.preventDefault();
  //   }

  //   if (!input.value) {
  //     e.target.value = 1;
  //     setHourAdvanceReminder(1);
  //     handleChangeHourAdvanceReminder(e);
  //   }
  // }

  function handleChangeAppointmentRecurrenceEndTime(e) {
    const inputValue = e.target.value;
    setAppointmentRecurrenceEndTime(inputValue);

    const validHHMMstring = (str) => /^([01]?[0-9]|2[0-3]):[0-5][0-9]$/.test(str);

    if (!inputValue) {
      setError({ field: 'appointmentRecurrenceEndTime', message: 'O horário de fim da recorrência é obrigatório' });
    }
    else if (!validHHMMstring(inputValue)) {
      setError({ field: 'appointmentRecurrenceEndTime', message: 'Horario inválido' });
    } else {
      removeError('appointmentRecurrenceEndTime');
    }
  }

  function handleChangeAppointmentRecurrenceEndDate(e) {
    const inputValue = e.target.value;
    setAppointmentRecurrenceEndDate(inputValue);

    if (e.target.value.length < 10) {
      setError({ field: 'appointmentRecurrenceEndDate', message: 'Data inválida' });
      return false;
    }

    if (!inputValue) {
      setError({ field: 'appointmentRecurrenceEndDate', message: 'A data fim da recorrência é obrigatória' });
      return false;
    }
    else if (inputValue.length === 10 && (!isMatch(inputValue, 'dd/MM/yyyy'))) {
      setError({ field: 'appointmentRecurrenceEndDate', message: 'Data inválida' });
      return false;
    }
    else {
      removeError('appointmentRecurrenceEndDate');
    }

    try {
      const [day, month, year] = inputValue.split('/');
      const date = new Date(+year, month - 1, +day);
      var today = new Date();
      today.setHours(0, 0, 0, 0);

      if (date) {
        if (date < today) {
          setError({ field: 'appointmentRecurrenceEndDate', message: invalidDateLesserThanTodayMessage });
          return false;
        }
        else {
          setAppointmentRecurrenceEndDate(inputValue);
          removeError('appointmentRecurrenceEndDate');
        }
      }
    }
    catch (err) {
      setError({ field: 'appointmentRecurrenceEndDate', message: 'Data inválida' });
      return false;
    }
  }

  function handleChangeAppointmentHasRecurrence(e) {
    const inputValue = e.target.checked;
    const appointmentHasRecurrence = (inputValue === true);
    setAppointmentHasRecurrence(appointmentHasRecurrence);

    if (!appointmentHasRecurrence) {
      setAppointmentRecurrenceTypeId(null);
    }

    // if (appointmentHasRecurrence) {
    //   setReminderDate1('');
    //   setReminderDate1Date(null);
    //   removeError('reminderDate1');
    //   setReminderDate2('');
    //   setReminderDate2Date(null);
    //   removeError('reminderDate2');
    //   setReminderDate3('');
    //   setReminderDate3Date(null);
    //   removeError('reminderDate3');
    // }
  }

  function handleChangeAppointmentHasPushNotifications(e) {
    const inputValue = e.target.checked;
    const appointmentHasPushNotifications = (inputValue === true);
    setAppointmentHasPushNotifications(appointmentHasPushNotifications);
  }

  function handleChangeAppointmentHasAdvanceReminder(e) {
    const inputValue = e.target.checked;
    const appointmentHasAdvanceReminder = (inputValue === true);
    setAppointmentHasAdvanceReminder(appointmentHasAdvanceReminder);

    // if (!appointmentHasAdvanceReminder) {
    //   setAppointmentRecurrenceTypeId(null);
    // }

    // if (appointmentHasAdvanceReminder) {
    //   setReminderDate1('');
    //   setReminderDate1Date(null);
    //   removeError('reminderDate1');
    //   setReminderDate2('');
    //   setReminderDate2Date(null);
    //   removeError('reminderDate2');
    //   setReminderDate3('');
    //   setReminderDate3Date(null);
    //   removeError('reminderDate3');
    // }
  }

  function handleChangeAppointmentHasAttachment(e) {
    const inputValue = e.target.checked;
    const appointmentHasAttachment = (inputValue === true);
    setAppointmentHasAttachment(appointmentHasAttachment);
  }

  function handleChangeShowDocumentValueAndBarCodeNumber(e) {
    const inputValue = e.target.checked;
    const showDocumentValueAndBarCodeNumber = (inputValue === true);
    setShowDocumentValueAndBarCodeNumber(showDocumentValueAndBarCodeNumber);
  }

  // function handleChangeAppointmentHasHourAdvanceReminder(e) {
  //   const inputValue = e.target.checked;
  //   const appointmentHasHourAdvanceReminder = (inputValue === true);
  //   setAppointmentHasHourAdvanceReminder(appointmentHasHourAdvanceReminder);
  // }

  function handleChangeAppointmentTime(e) {
    const inputValue = e.target.value;
    setAppointmentTime(inputValue);

    // const validHHMMstring = (str) => /^([01]?[0-9]|2[0-3]):[0-5][0-9]$/.test(str);

    // if (!inputValue && appointmentRecurrenceEndTime) {
    //   setError({ field: 'appointmentTime', message: 'A hora do compromisso é obrigatória' });
    //   setMustShowHourAdvanceReminderSwitch(false);
    // }
    // else if (!validHHMMstring(inputValue) && inputValue) {
    //   setError({ field: 'appointmentTime', message: 'Horario inválido' });
    //   setMustShowHourAdvanceReminderSwitch(false);
    // } else {
    //   removeError('appointmentTime');
    //   setMustShowHourAdvanceReminderSwitch(true);
    // }
  }

  function handleChangeDocumentValue(values, sourceInfo) {
    const value = (values.floatValue / 100);

    setRealDocumentValue(value); // valor real
    setDocumentValue(values.formattedValue); // valor formatado para exibição, somente
    console.log(sourceInfo);

    if (!value) {
      setError({ field: 'documentValue', message: 'O valor é obrigatório' });
    } else if (parseFloat(value) === 0) {
      setError({ field: 'documentValue', message: 'O valor não pode ser zero' });
    }
    else {
      removeError('documentValue');
    }
  }

  function handleChangeBarCodeNumber(e) {
    setBarCodeNumber(e.target.value);

    if (!e.target.value) {
      setError({ field: 'barCodeNumber', message: 'O código de barras é obrigatório' });
    }
    else {
      removeError('barCodeNumber');
    }
  }

  function handleAppointmentDateOnKeyPress(e) {
    let input = e.target;
    if (e.charCode < 47 || e.charCode > 57) {
      e.preventDefault();
    }

    var len = input.value.length;

    if (len === 0) {
      e.preventDefault();
      setError({ field: 'appointmentDate', message: 'Data inválida' });
    }
    else {
      removeError('appointmentDate');
    }

    // If we're at a particular place, let the user type the slash
    if (len !== 1 || len !== 3) {
      if (e.charCode === 47) {
        e.preventDefault();
      }
    }

    if (e.key !== 'Backspace') {
      // If they don't add the slash, do it for them...
      if (len === 2) {
        input.value += '/';
      }

      // If they don't add the slash, do it for them...
      if (len === 5) {
        input.value += '/';
      }
    }
  }

  function handleAppointmentRecurrenceEndDateOnKeyPress(e) {
    let input = e.target;
    if (e.charCode < 47 || e.charCode > 57) {
      e.preventDefault();
    }
    var len = input.value.length;

    if (len === 0) {
      e.preventDefault();
      setError({ field: 'appointmentRecurrenceEndDate', message: 'Data inválida' });
    }
    else {
      removeError('appointmentRecurrenceEndDate');
    }

    // If we're at a particular place, let the user type the slash
    if (len !== 1 || len !== 3) {
      if (e.charCode === 47) {
        e.preventDefault();
      }
    }

    if (e.key !== 'Backspace') {
      // If they don't add the slash, do it for them...
      if (len === 2) {
        input.value += '/';
      }

      // If they don't add the slash, do it for them...
      if (len === 5) {
        input.value += '/';
      }
    }
  }

  // function handleReminderDate1OnKeyPress(e) {
  //   let input = e.target;
  //   if (e.charCode < 47 || e.charCode > 57) {
  //     e.preventDefault();
  //   }
  //   var len = input.value.length;

  //   if (len === 0) {
  //     e.preventDefault();
  //     setError({ field: 'reminderDate1', message: 'Data inválida' });
  //   }
  //   else {
  //     removeError('reminderDate1');
  //   }

  //   // If we're at a particular place, let the user type the slash
  //   if (len !== 1 || len !== 3) {
  //     if (e.charCode === 47) {
  //       e.preventDefault();
  //     }
  //   }

  //   if (e.key !== 'Backspace') {
  //     // If they don't add the slash, do it for them...
  //     if (len === 2) {
  //       input.value += '/';
  //     }

  //     // If they don't add the slash, do it for them...
  //     if (len === 5) {
  //       input.value += '/';
  //     }
  //   }
  // }

  // function handleReminderDate2OnKeyPress(e) {
  //   let input = e.target;
  //   if (e.charCode < 47 || e.charCode > 57) {
  //     e.preventDefault();
  //   }
  //   var len = input.value.length;

  //   if (len === 0) {
  //     e.preventDefault();
  //     setError({ field: 'reminderDate2', message: 'Data inválida' });
  //   }
  //   else {
  //     removeError('reminderDate2');
  //   }

  //   // If we're at a particular place, let the user type the slash
  //   if (len !== 1 || len !== 3) {
  //     if (e.charCode === 47) {
  //       e.preventDefault();
  //     }
  //   }

  //   if (e.key !== 'Backspace') {
  //     // If they don't add the slash, do it for them...
  //     if (len === 2) {
  //       input.value += '/';
  //     }

  //     // If they don't add the slash, do it for them...
  //     if (len === 5) {
  //       input.value += '/';
  //     }
  //   }
  // }

  // function handleReminderDate3OnKeyPress(e) {
  //   let input = e.target;
  //   if (e.charCode < 47 || e.charCode > 57) {
  //     e.preventDefault();
  //   }
  //   var len = input.value.length;

  //   if (len === 0) {
  //     e.preventDefault();
  //     setError({ field: 'reminderDate3', message: 'Data inválida' });
  //   }
  //   else {
  //     removeError('reminderDate3');
  //   }

  //   // If we're at a particular place, let the user type the slash
  //   if (len !== 1 || len !== 3) {
  //     if (e.charCode === 47) {
  //       e.preventDefault();
  //     }
  //   }

  //   if (e.key !== 'Backspace') {
  //     // If they don't add the slash, do it for them...
  //     if (len === 2) {
  //       input.value += '/';
  //     }

  //     // If they don't add the slash, do it for them...
  //     if (len === 5) {
  //       input.value += '/';
  //     }
  //   }
  // }

  function handleAppointmentTimeOnKeyPress(e) {
    let input = e.target;
    if (e.charCode < 47 || e.charCode > 57) {
      e.preventDefault();
    }
    var len = input.value.length;
    // If we're at a particular place, let the user type the slash
    if (len !== 1 || len !== 3) {
      if (e.charCode === 47) {
        e.preventDefault();
      }
    }

    if (e.key !== 'Backspace') {
      if (len === 2) {
        input.value += ':';
      }
    }
  }

  function handleChangeAppointmentDate(e) {
    const inputValue = e.target.value;
    setAppointmentDate(inputValue);

    if (e.target.value.length < 10) {
      setError({ field: 'appointmentDate', message: 'Data inválida' });
      return false;
    }

    if (!inputValue) {
      setError({ field: 'appointmentDate', message: 'A data do compromisso é obrigatória' });
      return false;
    }
    else if (inputValue.length === 10 && (!isMatch(inputValue, 'dd/MM/yyyy'))) {
      setError({ field: 'appointmentDate', message: 'Data inválida' });
      return false;
    }
    else {
      removeError('appointmentDate');
    }

    try {
      const [day, month, year] = inputValue.split('/');
      const date = new Date(+year, month - 1, +day);
      var today = new Date();
      today.setHours(0, 0, 0, 0);

      if (date) {
        // if (date < today) {
        //   setError({ field: 'appointmentDate', message: invalidDateLesserThanTodayMessage });
        //   return false;
        // }
        // else {
        //   setAppointmentDateDate(date);
        //   removeError('appointmentDate');
        // }
        setAppointmentDateDate(date);
        removeError('appointmentDate');
      }
      else {
        setAppointmentDateDate(null);
      }
    }
    catch (err) {
      setError({ field: 'appointmentDate', message: 'Data inválida' });
      return false;
    }
  }

  // function handleChangeReminderDate1(e) {
  //   setReminderDate1(e.target.value);

  //   if (e.target.value.length === 0) {
  //     removeError('reminderDate1');
  //     setShowReminderDate2(false);
  //     setShowReminderDate3(false);
  //   }

  //   if (e.target.value.length < 10) {
  //     setError({ field: 'reminderDate1', message: 'Data inválida' });
  //     return false;
  //   } else if (e.target.value.length === 10 && (!isMatch(e.target.value, 'dd/MM/yyyy'))) {
  //     setError({ field: 'reminderDate1', message: 'Data inválida' });
  //     return false;
  //   }
  //   else if (e.target.value.length === 10 && (e.target.value === appointmentDate)) {
  //     setError({ field: 'reminderDate1', message: invalidReminderDateLesserThanMessage });
  //     return false;
  //   }
  //   else if (e.target.value.length === 10) {
  //     const inputValue = e.target.value;
  //     try {
  //       const [day, month, year] = inputValue.split('/');
  //       const date = new Date(+year, month - 1, +day);
  //       var today = new Date();
  //       today.setHours(0, 0, 0, 0);

  //       if (date) {
  //         setReminderDate1Date(date);

  //         if (date < today) {
  //           setError({ field: 'reminderDate1', message: invalidDateLesserThanTodayMessage });
  //           return false;
  //         }
  //         else if (date >= appointmentDateDate) {
  //           setError({ field: 'reminderDate1', message: invalidReminderDateAfteAppointmentDateMessage });
  //           return false;
  //         }
  //         else {
  //           removeError('reminderDate1');
  //           setShowReminderDate2(true);
  //           setShowReminderDate3(false);
  //         }
  //       }
  //       else {
  //         setReminderDate1Date(null);
  //       }
  //     }
  //     catch (err) {
  //       setError({ field: 'reminderDate1', message: 'Data inválida' });
  //       return false;
  //     }
  //   }
  //   else {
  //     setShowReminderDate2(false);
  //   }
  // }

  // function handleChangeReminderDate2(e) {
  //   setReminderDate2(e.target.value);

  //   if (e.target.value.length < 10) {
  //     setError({ field: 'reminderDate2', message: 'Data inválida' });
  //     return false;
  //   } else if (e.target.value.length === 10 && (!isMatch(e.target.value, 'dd/MM/yyyy'))) {
  //     setError({ field: 'reminderDate2', message: 'Data inválida' });
  //     return false;
  //   }
  //   else if (e.target.value.length === 10 && (e.target.value === reminderDate1)) {
  //     setError({ field: 'reminderDate2', message: invalidReminderDateLesserThanMessage });
  //     return false;
  //   }
  //   else if (e.target.value.length === 10) {
  //     const inputValue = e.target.value;
  //     try {
  //       const [day, month, year] = inputValue.split('/');
  //       const date = new Date(+year, month - 1, +day);
  //       var today = new Date();
  //       today.setHours(0, 0, 0, 0);

  //       if (date) {
  //         setReminderDate2Date(date);

  //         if (date < today) {
  //           setError({ field: 'reminderDate2', message: invalidDateLesserThanTodayMessage });
  //           return false;
  //         }
  //         else if (date >= appointmentDateDate) {
  //           setError({ field: 'reminderDate2', message: invalidReminderDateAfteAppointmentDateMessage });
  //           return false;
  //         }
  //         else if (date.toString() === reminderDate1Date.toString()) {
  //           setError({ field: 'reminderDate2', message: invalidAppointmentDateLesserThanReminderDateMessage });
  //           return false;
  //         }
  //         else {
  //           removeError('reminderDate2');
  //           setShowReminderDate3(true);
  //         }
  //       }
  //       else {
  //         setReminderDate2Date(null);
  //       }
  //     }
  //     catch (err) {
  //       setError({ field: 'reminderDate2', message: 'Data inválida' });
  //       return false;
  //     }
  //   }
  //   else {
  //     setShowReminderDate3(false);
  //   }
  // }

  // function handleChangeReminderDate3(e) {
  //   setReminderDate3(e.target.value);

  //   if (e.target.value.length < 10) {
  //     setError({ field: 'reminderDate3', message: 'Data inválida' });
  //     return false;
  //   } else if (e.target.value.length === 10 && (!isMatch(e.target.value, 'dd/MM/yyyy'))) {
  //     setError({ field: 'reminderDate3', message: 'Data inválida' });
  //     return false;
  //   }
  //   else if (e.target.value.length === 10 && (e.target.value === reminderDate1 || e.target.value === reminderDate2)) {
  //     setError({ field: 'reminderDate3', message: invalidReminderDateLesserThanMessage });
  //     return false;
  //   }
  //   else if (e.target.value.length === 10) {
  //     const inputValue = e.target.value;
  //     try {
  //       const [day, month, year] = inputValue.split('/');
  //       const date = new Date(+year, month - 1, +day);
  //       var today = new Date();
  //       today.setHours(0, 0, 0, 0);

  //       if (date) {
  //         setReminderDate3Date(date);

  //         if (date < today) {
  //           setError({ field: 'reminderDate2', message: invalidDateLesserThanTodayMessage });
  //           return false;
  //         }
  //         else if (date >= appointmentDateDate) {
  //           setError({ field: 'reminderDate3', message: invalidReminderDateAfteAppointmentDateMessage });
  //           return false;
  //         }
  //         else if (date.toString() === reminderDate1Date.toString()) {
  //           setError({ field: 'reminderDate3', message: invalidAppointmentDateLesserThanReminderDateMessage });
  //           return false;
  //         }
  //         else if (date.toString() === reminderDate2Date.toString()) {
  //           setError({ field: 'reminderDate3', message: invalidAppointmentDateLesserThanReminderDateMessage });
  //           return false;
  //         }
  //         else {
  //           removeError('reminderDate3');
  //         }
  //       }
  //       else {
  //         setReminderDate3Date(null);
  //       }
  //     }
  //     catch (err) {
  //       setError({ field: 'reminderDate3', message: 'Data inválida' });
  //       return false;
  //     }
  //   }
  // }

  // function handleDeleteReminder1(e) {
  //   setReminderDate1('');
  //   setShowReminderDate2(false);
  //   removeError('reminderDate1');

  // }

  // function handleDeleteReminder2(e) {
  //   setReminderDate2('');
  //   setShowReminderDate2(false);
  //   removeError('reminderDate2');
  // }

  // function handleDeleteReminder3(e) {
  //   setReminderDate3('');
  //   setShowReminderDate3(false);
  //   removeError('reminderDate3');
  // }

  function handleOpenModal(attachmentFile, attachmentFilePosition) {
    setIsModalOpen(true);
    setAttachmentFileToDelete(attachmentFile);
    setAttachmentFileToDeletePosition(attachmentFilePosition);
  }

  function handleCloseModal() {
    setIsModalOpen(false);
  }

  async function handleDelete(e) {
    setIsModalOpen(false);
    setIsModalOptionsOpen(false);
    setIsLoading(true);
    await new AppointmentsService(Config.API_BASE_URL).deleteAppointment({
      Id: appointmentToDelete.Id,
      OriginalAppointmentId: appointmentToDelete.OriginalAppointmentId ?? appointmentToDelete.Id,
    });
    setIsLoading(false);

    window.open('/', '_self');
  }

  async function handleDeleteRecurrenceOnly(e) {
    setIsModalOpen(false);
    setIsModalOptionsOpen(false);

    await new AppointmentsService(Config.API_BASE_URL).deleteAppointment({
      Id: appointmentToDelete.Id,
    });

    window.open('/', '_self');
  }

  function handleCloseDeleteModal() {
    setIsModalDeleteOpen(false);
  }

  function handleCloseModalOptions() {
    setIsModalOptionsOpen(false);
  }

  async function handleRemoveAttachment() {
    setIsModalOpen(false);
    setIsLoading(true);

    await onRemoveAttachment(attachmentFileToDelete);

    switch (attachmentFileToDeletePosition) {
      case 1:
        setMustShowAttachmentFileInput(true);
        break;
      case 2:
        setMustShowAttachmentFileInput2(true);
        break;
      case 3:
        setMustShowAttachmentFileInput3(true);
        break;
      case 4:
        setMustShowAttachmentFileInput4(true);
        break;
      case 5:
        setMustShowAttachmentFileInput5(true);
        break;
      default:
        setMustShowAttachmentFileInput(true);
        break;
    }
    setIsLoading(false);

  }

  async function onRemoveAttachment(attachmentFileToDelete) {
    await new AppointmentsService(Config.API_BASE_URL).deleteAppointmentAttachment({
      Id: attachmentFileToDelete.Id,
    });
  }

  function handleChangeAppointmentFiles(e) {
    if (e.target.files[0]) {
      setAppointmentFiles(e.target.files[0]);
    }
  }

  function handleChangeAppointmentFiles2(e) {
    if (e.target.files[0]) {
      setAppointmentFiles2(e.target.files[0]);
    }
  }

  function handleChangeAppointmentFiles3(e) {
    if (e.target.files[0]) {
      setAppointmentFiles3(e.target.files[0]);
    }
  }

  function handleChangeAppointmentFiles4(e) {
    if (e.target.files[0]) {
      setAppointmentFiles4(e.target.files[0]);
    }
  }

  function handleChangeAppointmentFiles5(e) {
    if (e.target.files[0]) {
      setAppointmentFiles5(e.target.files[0]);
    }
  }

  function handleChangeAppointmentType(option) {
    setAppointmentTypeOption(option);

    if (option) {
      setAppointmentTypeId(option.value);
    } else {
      setAppointmentTypeId(null);
    }

    if (!option) {
      setError({ field: 'appointmentTypeId', message: 'O tipo é obrigatório' });
    }
    else {
      removeError('appointmentTypeId');
    }
  }

  return (
    <>
      <GoBackHeader
        backAction={"/"}
        title={((!appointment.Id) ? "Novo compromisso" : "Editar compromisso")}
        mustShowInfoIcon={true}
      />
      <div className='basic-container'>
        <Form onSubmit={handleSubmit} noValidate
          className="form"
        >
          <Loader isLoading={isLoading} />
          <Modal
            title='Deletar arquivo'
            body='Este arquivo será deletado permanentemente do seu compromisso e não estará mais disponível.'
            isVisible={isModalOpen}
            danger
            onConfirm={handleRemoveAttachment}
            onCancel={handleCloseModal}
          />

          {/* <Modal
            title={tipTitle}
            body={tipBody}
            tip
            isVisible={isTipModalOpen}
            onConfirm={() => setIsTipModalOpen(false)}
            onCancel={() => setIsTipModalOpen(false)}
          /> */}

          <Modal
            title='Deletar compromisso'
            body='Deseja realmente deletar este compromisso? '
            isVisible={isModalDeleteOpen}
            danger
            onConfirm={handleDelete}
            onCancel={handleCloseDeleteModal}
          />

          <ModalOptions
            title='Deletar compromisso'
            body='Deseja realmente deletar este compromisso? 

        Ele possui recorrências!'
            isVisible={isModalOptionsOpen}
            danger
            onCancel={handleCloseModalOptions}
            onOption1={handleDelete}
            onOption2={handleDeleteRecurrenceOnly}
            option1Text='Deletar todos'
            option2Text='Deletar esta ocorrência'
          />

          <FormGroup
            error={getErrorMessageByFieldName('appointmentTypeId')}
          >
            <div className='form-label'>Tipo</div>
            <CreatableSelect
              isClearable
              className="creatable-select"
              onChange={(newValue) => handleChangeAppointmentType(newValue)}
              onCreateOption={handleCreateAppointmentType}
              options={appointmentTypes && (
                appointmentTypes.map((appointmentType) => (
                  createOption(appointmentType.Id, appointmentType.Name)
                )))}
              formatCreateLabel={(inputText) => `Criar ${inputText}`}
              value={appointmentTypeOption}
              placeholder='Selecione'
              isDisabled={isFieldsDisabled}
            />
          </FormGroup>

          <FormGroup
            error={getErrorMessageByFieldName('name')}
          >
            <div className='form-label'>Nome do compromisso</div>
            {/* <Input
              type='text'
              value={name}
              placeholder='Insira o nome do compromisso'
              onChange={handleChangeName}
              error={getErrorMessageByFieldName('name')}
              disabled={isFieldsDisabled}
            /> */}

            <InputAuto
              autoFocus
              value={name}
              onChange={handleChangeNameAutoTitle}
              data={titles}
              placeholder='Insira o nome do compromisso'
              disabled={isFieldsDisabled}
            />
          </FormGroup>

          <FormGroup
            error={getErrorMessageByFieldName('appointmentDate')}
            className=''
          // style={{
          //   display: 'flex',
          //   maxWidth: '50%'
          // }}
          >
            <img src={CalendarIcon} alt='date' className="input-icon" />
            <div className='form-label'>Data</div>
            <Calendar
              onChange={
                (date) => {
                  setAppointmentDateDate(date);
                  setAppointmentDate(date.toLocaleDateString('pt-br', { year: "numeric", month: "numeric", day: "numeric" }));
                  var today = new Date();
                  today.setHours(0, 0, 0, 0);

                  if (date) {
                    setAppointmentDateDate(date);
                    removeError('appointmentDate');
                  }
                  else {
                    setAppointmentDateDate(null);
                  }
                }
              }
              locale="pt-br"
              dateFormat="dd/MM/yyyy"
              selected={appointmentDateDate}
              showYearDropdown
              dropdownMode="select"
              disabled={isFieldsDisabled}
              placeholderText='dd/mm/aaaa'
              customInput={
                <Input
                  type="text"
                  maxLength="10"
                  placeholder='dd/mm/aaaa'
                  value={appointmentDate}
                  onChange={handleChangeAppointmentDate}
                  onKeyPress={handleAppointmentDateOnKeyPress}
                  onKeyUp={handleAppointmentDateOnKeyPress}
                  error={getErrorMessageByFieldName('appointmentDate')}
                  disabled={isFieldsDisabled}
                />
              }
            />
          </FormGroup>

          {mustShowAppointmentTime && (
            <>
              <FormGroup
                error={getErrorMessageByFieldName('appointmentTime')}
              >
                <div className='form-label'>Hora</div>
                <img src={ClockIcon} alt='time' className="input-icon" />

                <Input
                  type='text'
                  value={appointmentTime}
                  maxLength="5"
                  placeholder='hh:mm'
                  onChange={handleChangeAppointmentTime}
                  onKeyPress={handleAppointmentTimeOnKeyPress}
                  onKeyUp={handleAppointmentTimeOnKeyPress}
                  error={getErrorMessageByFieldName('appointmentTime')}
                  disabled={isFieldsDisabled}
                />
                {/* <img
                  onClick={() => {
                    setTipTitle('Atenção');
                    setTipBody('Ao preencher a “hora do compromisso”, as notificações do TeLembro funcionarão com base no que foi preenchido. Do contrário, a hora base será meia-noite.Fuso horário: TeLembro considerará o que foi cadastrado nas configurações.');
                    setIsTipModalOpen(true);
                  }}
                  src={OrangeInfoButton}
                  alt='info'
                  style={{
                    marginLeft: '5px',
                    cursor: 'pointer',
                  }}
                /> */}
              </FormGroup>

              {/* {mustShowHourAdvanceReminderSwitch && (
            <>
              <FormGroup
                error={getErrorMessageByFieldName('appointmentHasHourAdvanceReminder')}
                className='toggle'
              >
                <div className='side-label'>Alerta com antecedência de?</div>
                <div
                  className='toggleWrapper'
                >
                  <input
                    id={`c-${appointment.Id}`}
                    type="checkbox"
                    className='mobileToggle'
                    key={`c-${appointment.Id}`}
                    checked={(appointmentHasHourAdvanceReminder === true)}
                    onChange={handleChangeAppointmentHasHourAdvanceReminder}
                    disabled={isFieldsDisabled}
                  // onChange={(e) => {
                  //   handleCheckboxChange(appointment.Id);
                  // }}
                  />
                  <label htmlFor={`c-${appointment.Id}`}></label>
                </div>
              </FormGroup>
            </>
          )} */}

              {/* {(appointmentHasHourAdvanceReminder && mustShowHourAdvanceReminderSwitch) && (
            <>
              <div>
                <FormGroup
                  error={getErrorMessageByFieldName('hourAdvanceReminder')}
                  className='complexcounter toggle'
                >
                  <div className='side-label'>Horas</div>
                  <div className="group recurrenceunit">
                    <button
                      type="button"
                      onClick={decrementCount2}
                      className="counter"
                    >-</button>
                    <Input
                      type='text'
                      value={hourAdvanceReminder}
                      placeholder='0'
                      onChange={handleChangeHourAdvanceReminder}
                      onKeyPress={handleHourAdvanceReminderOnKeyPress}
                      onKeyUp={handleHourAdvanceReminderOnKeyPress}
                      error={getErrorMessageByFieldName('hourAdvanceReminder')}
                      disabled={isFieldsDisabled}
                    />
                    <button
                      type="button"
                      onClick={incrementCount2}
                      className="counter"
                    >+</button>
                  </div>
                </FormGroup>

              </div>
            </>
          )
          } */}
            </>
          )}

          <FormGroup
            error={getErrorMessageByFieldName('observations')}
          >
            <div className='form-label'>Observações <span>(opcional)</span></div>
            <TextArea
              type='text'
              value={observations}
              placeholder='Insira aqui possíveis observações'
              onChange={handleChangeObservations}
              error={getErrorMessageByFieldName('observations')}
              disabled={isFieldsDisabled}
              onKeyDown={e => {
                if (e.key === 'Enter') {
                  e.preventDefault();
                }

                e.target.style.height = 'inherit';
                e.target.style.height = `${e.target.scrollHeight}px`;
              }}
            />
            {/* <div className='counter'>
              {`${observationsCharCounter}/300`}
            </div> */}
          </FormGroup>
          <div className='side-label'>Convidar participantes <span>(opcional)</span></div>

          {sharedUsers.map((user, index) => (
            <FormGroup
              error={getErrorMessageByFieldName('sharedUsers')}
            >
              <div className='form-label'>E-mail</div>

              <div key={user.id} className="shared-user-item"
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  gap: '12px'
                }}
              >
                <InputAuto
                  placeholder="Insira o email do participante"
                  data={emails} // Aqui você pode passar sugestões de emails se tiver
                  value={user.email}
                  onChange={(value) => {
                    updateSharedUserEmail(user.id, value);
                  }}
                  disabled={isFieldsDisabled}
                />
                {sharedUsers.length > 1 && (
                  <img src={Trash}
                    alt='Trash'
                    onClick={(e) => {
                      if (isFieldsDisabled) {
                        return false;
                      }
                      removeSharedUser(user.id);
                    }}
                    style={{
                      cursor: 'pointer'
                    }}
                  />
                )}
              </div>

            </FormGroup>
          ))}
          <FormGroup
            error={getErrorMessageByFieldName('sharedUsers')}
          >
            <a href="#t"
              onClick={() => {
                if (isFieldsDisabled) {
                  return false;
                }

                addSharedUser();
              }}
              className="add-button"
              style={{
                display: 'flex',
                justifyContent: 'flex-start',
                gap: '10px',
                marginBottom: '16px'
              }}
            >
              Adicionar
              <img src={AddParticipantForm} alt='add' />
            </a>
          </FormGroup>
          {/* {!appointmentHasRecurrence && (
        <>
          <FormGroup
            error={getErrorMessageByFieldName('reminderDate1')}
            className='complex'
          >
            <div className='form-label'>Lembrar também em</div>
            <div className="group">
              <Calendar
                onChange={
                  (date) => {
                    setReminderDate1Date(date);
                    setReminderDate1(date.toLocaleDateString('pt-br', { year: "numeric", month: "numeric", day: "numeric" }));

                    var today = new Date();
                    today.setHours(0, 0, 0, 0);

                    if (date) {
                      setReminderDate1Date(date);

                      if (date < today) {
                        setError({ field: 'reminderDate1', message: invalidDateLesserThanTodayMessage });
                        return false;
                      }
                      else if (date >= appointmentDateDate) {
                        setError({ field: 'reminderDate1', message: invalidReminderDateAfteAppointmentDateMessage });
                        return false;
                      }
                      else {
                        removeError('reminderDate1');
                        setShowReminderDate2(true);
                        setShowReminderDate3(false);
                      }
                    }
                    else {
                      setReminderDate1Date(null);
                    }
                  }
                }
                locale="pt-br"
                dateFormat="dd/MM/yyyy"
                selected={reminderDate1Date}
                placeholder='__/__/____'
                showYearDropdown
                dropdownMode="select"
                customInput={
                  <Input
                    type="text"
                    value={reminderDate1}
                    maxLength="10"
                    placeholder='(dd/mm/aaaa)'
                    onChange={handleChangeReminderDate1}
                    onKeyPress={handleReminderDate1OnKeyPress}
                    onKeyUp={handleReminderDate1OnKeyPress}
                    error={getErrorMessageByFieldName('reminderDate1')}
                    disabled={isFieldsDisabled}
                  />
                }
              />
              {(!isFieldsDisabled) && (
                <button
                  type="button"
                  onClick={(e) => {
                    handleDeleteReminder1(e);
                  }}
                >
                  <img src={Trash} alt='Trash' />
                </button>
              )}
            </div>
          </FormGroup>

          {showReminderDate2 && (
            <FormGroup
              error={getErrorMessageByFieldName('reminderDate2')}
              className='complex'
            >
              <div className='form-label'>Lembrar também em</div>
              <div className="group">
                <Calendar
                  onChange={
                    (date) => {
                      setReminderDate2Date(date);
                      setReminderDate2(date.toLocaleDateString('pt-br', { year: "numeric", month: "numeric", day: "numeric" }));

                      var today = new Date();
                      today.setHours(0, 0, 0, 0);

                      if (date) {
                        setReminderDate2Date(date);

                        if (date < today) {
                          setError({ field: 'reminderDate2', message: invalidDateLesserThanTodayMessage });
                          return false;
                        }
                        else if (date >= appointmentDateDate) {
                          setError({ field: 'reminderDate2', message: invalidReminderDateAfteAppointmentDateMessage });
                          return false;
                        }
                        else if (date.toString() === reminderDate1Date.toString()) {
                          setError({ field: 'reminderDate2', message: invalidAppointmentDateLesserThanReminderDateMessage });
                          return false;
                        }
                        else {
                          removeError('reminderDate2');
                          setShowReminderDate3(true);
                        }
                      }
                      else {
                        setReminderDate2Date(null);
                      }
                    }
                  }
                  locale="pt-br"
                  dateFormat="dd/MM/yyyy"
                  selected={reminderDate2Date}
                  placeholder='__/__/____'
                  showYearDropdown
                  dropdownMode="select"
                  customInput={
                    <Input
                      type="text"
                      value={reminderDate2}
                      maxLength="10"
                      placeholder='(dd/mm/aaaa)'
                      onChange={handleChangeReminderDate2}
                      onKeyPress={handleReminderDate2OnKeyPress}
                      onKeyUp={handleReminderDate2OnKeyPress}
                      error={getErrorMessageByFieldName('reminderDate2')}
                      disabled={isFieldsDisabled}
                    />
                  }
                />
                {(!isFieldsDisabled) && (
                  <button
                    type="button"
                    onClick={(e) => {
                      handleDeleteReminder2(e);
                    }}
                  >
                    <img src={Trash} alt='Trash' />
                  </button>
                )}
              </div>
            </FormGroup>
          )}

          {showReminderDate3 && (
            <FormGroup
              error={getErrorMessageByFieldName('reminderDate3')}
              className='complex'
            >
              <div className='form-label'>Lembrar também em</div>
              <div className="group">
                <Calendar
                  onChange={
                    (date) => {
                      setReminderDate3Date(date);
                      setReminderDate3(date.toLocaleDateString('pt-br', { year: "numeric", month: "numeric", day: "numeric" }));

                      var today = new Date();
                      today.setHours(0, 0, 0, 0);

                      if (date) {
                        setReminderDate3Date(date);

                        if (date < today) {
                          setError({ field: 'reminderDate2', message: invalidDateLesserThanTodayMessage });
                          return false;
                        }
                        else if (date >= appointmentDateDate) {
                          setError({ field: 'reminderDate3', message: invalidReminderDateAfteAppointmentDateMessage });
                          return false;
                        }
                        else if (date.toString() === reminderDate1Date.toString()) {
                          setError({ field: 'reminderDate3', message: invalidAppointmentDateLesserThanReminderDateMessage });
                          return false;
                        }
                        else if (date.toString() === reminderDate2Date.toString()) {
                          setError({ field: 'reminderDate3', message: invalidAppointmentDateLesserThanReminderDateMessage });
                          return false;
                        }
                        else {
                          removeError('reminderDate3');
                        }
                      }
                      else {
                        setReminderDate3Date(null);
                      }
                    }
                  }
                  locale="pt-br"
                  dateFormat="dd/MM/yyyy"
                  selected={reminderDate3Date}
                  placeholder='__/__/____'
                  showYearDropdown
                  dropdownMode="select"
                  customInput={
                    <Input
                      type="text"
                      value={reminderDate3}
                      maxLength="10"
                      placeholder='(dd/mm/aaaa)'
                      onChange={handleChangeReminderDate3}
                      onKeyPress={handleReminderDate3OnKeyPress}
                      onKeyUp={handleReminderDate3OnKeyPress}
                      error={getErrorMessageByFieldName('reminderDate3')}
                      disabled={isFieldsDisabled}
                    />
                  }
                />
                {(!isFieldsDisabled) && (
                  <button
                    type="button"
                    onClick={(e) => {
                      handleDeleteReminder3(e);
                    }}
                  >
                    <img src={Trash} alt='Trash' />
                  </button>
                )}
              </div>
            </FormGroup>
          )}
        </>
      )} */}

          <>
            <FormGroup
              error={getErrorMessageByFieldName('appointmentHasPushNotifications')}
              className='toggle'
            >
              <div className='side-label'>Receber notificação Push</div>
              <SmallerToggleSwitch
                id={`p-${appointment.Id}`}
                checked={(appointmentHasPushNotifications === true)}
                onChange={handleChangeAppointmentHasPushNotifications}
                disabled={isFieldsDisabled}
              />
            </FormGroup>
          </>

          <>
            <FormGroup
              error={getErrorMessageByFieldName('appointmentHasAdvanceReminder')}
              className='toggle'
            >
              <div className='side-label'>Lembrar em:</div>
              <SmallerToggleSwitch
                id={`advancereminder-${appointment.Id}`}
                checked={(appointmentHasAdvanceReminder === true)}
                onChange={handleChangeAppointmentHasAdvanceReminder}
                disabled={isFieldsDisabled}
              />
            </FormGroup>

            {appointmentHasAdvanceReminder && (
              <>
                <div>
                  <FormGroup
                    error={getErrorMessageByFieldName('appointmentAdvanceReminderUnit1')}
                    className='complexcounter toggle'
                  >
                    <div className="group recurrenceunit">
                      <img src={CounterArrowUp} alt='Up'
                        onClick={incrementAdvanceReminderCount1}
                        className="counter-up"
                      />
                      <Input
                        type='text'
                        value={appointmentAdvanceReminderUnit1}
                        placeholder='0'
                        onChange={handleChangeAppointmentAdvanceReminderUnit1}
                        onKeyPress={handleAppointmentAdvanceReminderUnit1OnKeyPress}
                        onKeyUp={handleAppointmentAdvanceReminderUnit1OnKeyPress}
                        error={getErrorMessageByFieldName('appointmentAdvanceReminderUnit1')}
                        disabled={areRecurrenceFieldsDisabled || isFieldsDisabled}
                      />
                      <img src={CounterArrowDown} alt='Down'
                        onClick={decrementAdvanceReminderCount1}
                        className="counter-down"
                      />
                    </div>
                    <FormGroup
                      error={getErrorMessageByFieldName('appointmentAdvanceReminderType1')}
                      className='small-select'
                    >
                      <div className='form-label'>Selecione um período</div>
                      <Select
                        value={appointmentAdvanceReminderType1}
                        onChange={handleChangeAppointmentAdvanceReminderType1}
                        error={getErrorMessageByFieldName('appointmentAdvanceReminderType1')}
                        placeholder='Selecione'
                        disabled={areRecurrenceFieldsDisabled || isFieldsDisabled}
                        className="small"
                      >
                        <option value="">Selecione</option>
                        {appointmentAdvanceReminderTypes && (
                          appointmentAdvanceReminderTypes.map((appointmentAdvanceReminderType) => (
                            <option key={appointmentAdvanceReminderType.Id} value={appointmentAdvanceReminderType.Id}>{appointmentAdvanceReminderType.Name}</option>
                          )))}
                      </Select>
                    </FormGroup>
                    {(!isFieldsDisabled) && (
                      <button
                        type="button"
                        onClick={(e) => {
                          handleDeleteAdvanceReminder1(e);
                        }}
                        className="trash-button"
                      >
                        <img src={Trash} alt='Trash' />
                      </button>
                    )}
                  </FormGroup>
                </div>

                {(showAdvanceReminder2) && (
                  <div>
                    <FormGroup
                      error={getErrorMessageByFieldName('appointmentAdvanceReminderUnit2')}
                      className='complexcounter toggle'
                    >
                      <div className="group recurrenceunit">
                        <img src={CounterArrowUp} alt='Up'
                          onClick={incrementAdvanceReminderCount2}
                          className="counter-up"
                        />
                        <Input
                          type='text'
                          value={appointmentAdvanceReminderUnit2}
                          placeholder='0'
                          onChange={handleChangeAppointmentAdvanceReminderUnit2}
                          onKeyPress={handleAppointmentAdvanceReminderUnit2OnKeyPress}
                          onKeyUp={handleAppointmentAdvanceReminderUnit2OnKeyPress}
                          error={getErrorMessageByFieldName('appointmentAdvanceReminderUnit2')}
                          disabled={areRecurrenceFieldsDisabled || isFieldsDisabled}
                        />
                        <img src={CounterArrowDown} alt='Down'
                          onClick={decrementAdvanceReminderCount2}
                          className="counter-down"
                        />
                      </div>
                      <FormGroup
                        error={getErrorMessageByFieldName('appointmentAdvanceReminderType2')}
                        className='small-select'
                      >
                        <div className='form-label'>Selecione um período</div>
                        <Select
                          value={appointmentAdvanceReminderType2}
                          onChange={handleChangeAppointmentAdvanceReminderType2}
                          error={getErrorMessageByFieldName('appointmentAdvanceReminderType2')}
                          placeholder='Selecione'
                          disabled={areRecurrenceFieldsDisabled || isFieldsDisabled}
                          className="small"
                        >
                          <option value="">Selecione</option>
                          {appointmentAdvanceReminderTypes && (
                            appointmentAdvanceReminderTypes.map((appointmentAdvanceReminderType) => (
                              <option key={appointmentAdvanceReminderType.Id} value={appointmentAdvanceReminderType.Id}>{appointmentAdvanceReminderType.Name}</option>
                            )))}
                        </Select>
                      </FormGroup>
                      {(!isFieldsDisabled) && (
                        <button
                          type="button"
                          onClick={(e) => {
                            handleDeleteAdvanceReminder2(e);
                          }}
                          className="trash-button"
                        >
                          <img src={Trash} alt='Trash' />
                        </button>
                      )}
                    </FormGroup>
                  </div>
                )}

                {(showAdvanceReminder3) && (
                  <div>
                    <FormGroup
                      error={getErrorMessageByFieldName('appointmentAdvanceReminderUnit3')}
                      className='complexcounter toggle'
                    >
                      <div className="group recurrenceunit">
                        <img src={CounterArrowUp} alt='Up'
                          onClick={incrementAdvanceReminderCount3}
                          className="counter-up"
                        />
                        <Input
                          type='text'
                          value={appointmentAdvanceReminderUnit3}
                          placeholder='0'
                          onChange={handleChangeAppointmentAdvanceReminderUnit3}
                          onKeyPress={handleAppointmentAdvanceReminderUnit3OnKeyPress}
                          onKeyUp={handleAppointmentAdvanceReminderUnit3OnKeyPress}
                          error={getErrorMessageByFieldName('appointmentAdvanceReminderUnit3')}
                          disabled={areRecurrenceFieldsDisabled || isFieldsDisabled}
                        />
                        <img src={CounterArrowDown} alt='Down'
                          onClick={decrementAdvanceReminderCount3}
                          className="counter-down"
                        />
                      </div>
                      <FormGroup
                        error={getErrorMessageByFieldName('appointmentAdvanceReminderType3')}
                        className='small-select'
                      >
                        <div className='form-label'>Selecione um período</div>
                        <Select
                          value={appointmentAdvanceReminderType3}
                          onChange={handleChangeAppointmentAdvanceReminderType3}
                          error={getErrorMessageByFieldName('appointmentAdvanceReminderType3')}
                          placeholder='Selecione'
                          disabled={areRecurrenceFieldsDisabled || isFieldsDisabled}
                          className="small"
                        >
                          <option value="">Selecione</option>
                          {appointmentAdvanceReminderTypes && (
                            appointmentAdvanceReminderTypes.map((appointmentAdvanceReminderType) => (
                              <option key={appointmentAdvanceReminderType.Id} value={appointmentAdvanceReminderType.Id}>{appointmentAdvanceReminderType.Name}</option>
                            )))}
                        </Select>
                      </FormGroup>
                      {(!isFieldsDisabled) && (
                        <button
                          type="button"
                          onClick={(e) => {
                            handleDeleteAdvanceReminder3(e);
                          }}
                          className="trash-button"
                        >
                          <img src={Trash} alt='Trash' />
                        </button>
                      )}
                    </FormGroup>
                  </div>
                )}

                {(showAdvanceReminder4) && (
                  <div>
                    <FormGroup
                      error={getErrorMessageByFieldName('appointmentAdvanceReminderUnit4')}
                      className='complexcounter toggle'
                    >
                      <div className="group recurrenceunit">
                        <img src={CounterArrowUp} alt='Up'
                          onClick={incrementAdvanceReminderCount4}
                          className="counter-up"
                        />
                        <Input
                          type='text'
                          value={appointmentAdvanceReminderUnit4}
                          placeholder='0'
                          onChange={handleChangeAppointmentAdvanceReminderUnit4}
                          onKeyPress={handleAppointmentAdvanceReminderUnit4OnKeyPress}
                          onKeyUp={handleAppointmentAdvanceReminderUnit4OnKeyPress}
                          error={getErrorMessageByFieldName('appointmentAdvanceReminderUnit4')}
                          disabled={areRecurrenceFieldsDisabled || isFieldsDisabled}
                        />
                        <img src={CounterArrowDown} alt='Down'
                          onClick={decrementAdvanceReminderCount4}
                          className="counter-down"
                        />
                      </div>
                      <FormGroup
                        error={getErrorMessageByFieldName('appointmentAdvanceReminderType4')}
                        className='small-select'
                      >
                        <div className='form-label'>Selecione um período</div>
                        <Select
                          value={appointmentAdvanceReminderType4}
                          onChange={handleChangeAppointmentAdvanceReminderType4}
                          error={getErrorMessageByFieldName('appointmentAdvanceReminderType4')}
                          placeholder='Selecione'
                          disabled={areRecurrenceFieldsDisabled || isFieldsDisabled}
                          className="small"
                        >
                          <option value="">Selecione</option>
                          {appointmentAdvanceReminderTypes && (
                            appointmentAdvanceReminderTypes.map((appointmentAdvanceReminderType) => (
                              <option key={appointmentAdvanceReminderType.Id} value={appointmentAdvanceReminderType.Id}>{appointmentAdvanceReminderType.Name}</option>
                            )))}
                        </Select>
                      </FormGroup>
                      {(!isFieldsDisabled) && (
                        <button
                          type="button"
                          onClick={(e) => {
                            handleDeleteAdvanceReminder4(e);
                          }}
                          className="trash-button"
                        >
                          <img src={Trash} alt='Trash' />
                        </button>
                      )}
                    </FormGroup>
                  </div>
                )}

                {(showAdvanceReminder5) && (
                  <div>
                    <FormGroup
                      error={getErrorMessageByFieldName('appointmentAdvanceReminderUnit5')}
                      className='complexcounter toggle'
                    >
                      <div className="group recurrenceunit">
                        <img src={CounterArrowUp} alt='Up'
                          onClick={incrementAdvanceReminderCount5}
                          className="counter-up"
                        />
                        <Input
                          type='text'
                          value={appointmentAdvanceReminderUnit5}
                          placeholder='0'
                          onChange={handleChangeAppointmentAdvanceReminderUnit5}
                          onKeyPress={handleAppointmentAdvanceReminderUnit5OnKeyPress}
                          onKeyUp={handleAppointmentAdvanceReminderUnit5OnKeyPress}
                          error={getErrorMessageByFieldName('appointmentAdvanceReminderUnit5')}
                          disabled={areRecurrenceFieldsDisabled || isFieldsDisabled}
                        />
                        <img src={CounterArrowDown} alt='Down'
                          onClick={decrementAdvanceReminderCount5}
                          className="counter-down"
                        />
                      </div>
                      <FormGroup
                        error={getErrorMessageByFieldName('appointmentAdvanceReminderType5')}
                        className='small-select'
                      >
                        <div className='form-label'>Selecione um período</div>
                        <Select
                          value={appointmentAdvanceReminderType5}
                          onChange={handleChangeAppointmentAdvanceReminderType5}
                          error={getErrorMessageByFieldName('appointmentAdvanceReminderType5')}
                          placeholder='Selecione'
                          disabled={areRecurrenceFieldsDisabled || isFieldsDisabled}
                          className="small"
                        >
                          <option value="">Selecione</option>
                          {appointmentAdvanceReminderTypes && (
                            appointmentAdvanceReminderTypes.map((appointmentAdvanceReminderType) => (
                              <option key={appointmentAdvanceReminderType.Id} value={appointmentAdvanceReminderType.Id}>{appointmentAdvanceReminderType.Name}</option>
                            )))}
                        </Select>
                      </FormGroup>
                      {(!isFieldsDisabled) && (
                        <button
                          type="button"
                          onClick={(e) => {
                            handleDeleteAdvanceReminder5(e);
                          }}
                          className="trash-button"
                        >
                          <img src={Trash} alt='Trash' />
                        </button>
                      )}
                    </FormGroup>
                  </div>
                )}
              </>
            )}
          </>

          <>
            <FormGroup
              error={getErrorMessageByFieldName('appointmentHasRecurrence')}
              className='toggle'
            >
              <div className='side-label'>Recorrente?</div>
              <SmallerToggleSwitch
                id={`a-${appointment.Id}`}
                checked={(appointmentHasRecurrence === true)}
                onChange={handleChangeAppointmentHasRecurrence}
                disabled={isFieldsDisabled}
              />
            </FormGroup>

            {appointmentHasRecurrence && (
              <>
                <div>
                  <FormGroup
                    error={getErrorMessageByFieldName('appointmentRecurrenceUnity')}
                    className='complexcounter toggle'
                  >
                    {/* <div className='side-label'>A cada</div> */}
                    <div className="group recurrenceunit">
                      <img src={CounterArrowUp} alt='Up'
                        onClick={incrementCount}
                        className="counter-up"
                      />
                      <Input
                        type='text'
                        value={appointmentRecurrenceUnity}
                        placeholder='0'
                        onChange={handleChangeAppointmentRecurrenceUnity}
                        onKeyPress={handleAppointmentRecurrenceUnityOnKeyPress}
                        onKeyUp={handleAppointmentRecurrenceUnityOnKeyPress}
                        error={getErrorMessageByFieldName('appointmentRecurrenceUnity')}
                        disabled={areRecurrenceFieldsDisabled || isFieldsDisabled}
                      />
                      <img src={CounterArrowDown} alt='Down'
                        onClick={decrementCount}
                        className="counter-down"
                      />
                    </div>
                    <FormGroup
                      error={getErrorMessageByFieldName('appointmentRecurrenceTypeId')}
                      className='small-select'
                    >
                      <div className='form-label'>Período</div>
                      <Select
                        value={appointmentRecurrenceTypeId}
                        onChange={handleChangeAppointmentRecurrenceTypeId}
                        error={getErrorMessageByFieldName('appointmentRecurrenceTypeId')}
                        placeholder='Selecione'
                        disabled={areRecurrenceFieldsDisabled || isFieldsDisabled}
                      >
                        <option value="">Selecione</option>
                        {appointmentRecurrenceTypes && (
                          appointmentRecurrenceTypes.map((appointmentRecurrenceType) => (
                            <option key={appointmentRecurrenceType.Id} value={appointmentRecurrenceType.Id}>{appointmentRecurrenceType.Name}</option>
                          )))}
                      </Select>
                    </FormGroup>
                    {(!isFieldsDisabled) && (
                      <button
                        type="button"
                        onClick={(e) => {
                          setAppointmentRecurrenceUnity(1);
                          setAppointmentRecurrenceTypeId(0);
                        }}
                        className="trash-button"
                      >
                        <img src={Trash} alt='Trash' />
                      </button>
                    )}
                  </FormGroup>
                </div>

                <FormGroup
                  error={getErrorMessageByFieldName('appointmentRecurrenceEndDate')}
                >
                  <div className='form-label'>Data de fim da recorrência</div>
                  <Calendar
                    onChange={
                      (date) => {
                        setAppointmentRecurrenceEndDateDate(date);
                        setAppointmentRecurrenceEndDate(date.toLocaleDateString('pt-br', { year: "numeric", month: "numeric", day: "numeric" }));

                        // removeError('appointmentDate');
                        var today = new Date();
                        today.setHours(0, 0, 0, 0);

                        if (date) {
                          if (date < today) {
                            setError({ field: 'appointmentRecurrenceEndDate', message: invalidDateLesserThanTodayMessage });
                            return false;
                          }
                          else {
                            setAppointmentRecurrenceEndDateDate(date);
                            removeError('appointmentRecurrenceEndDate');
                          }
                        }
                        else {
                          setAppointmentRecurrenceEndDate(null);
                        }
                      }
                    }
                    locale="pt-br"
                    dateFormat="dd/MM/yyyy"
                    selected={appointmentRecurrenceEndDateDate}
                    placeholder='__/__/____'
                    showYearDropdown
                    dropdownMode="select"
                    //yearDropdownItemNumber={50}
                    customInput={
                      <Input
                        type="text"
                        value={appointmentRecurrenceEndDate}
                        maxLength="10"
                        placeholder='Data de fim da recorrência (dd/mm/aaaa)'
                        onChange={handleChangeAppointmentRecurrenceEndDate}
                        onKeyPress={handleAppointmentRecurrenceEndDateOnKeyPress}
                        onKeyUp={handleAppointmentRecurrenceEndDateOnKeyPress}
                        error={getErrorMessageByFieldName('appointmentDate')}
                        disabled={areRecurrenceFieldsDisabled || isFieldsDisabled}
                      />
                    }
                  />
                </FormGroup>

                {
                  showRecurrenceEndTime && (
                    <>
                      <FormGroup
                        error={getErrorMessageByFieldName('appointmentRecurrenceEndTime')}
                      >
                        <div className='form-label'>Hora de fim da recorrência</div>
                        <Input
                          type='text'
                          value={appointmentRecurrenceEndTime}
                          maxLength="5"
                          placeholder='Hora de fim da recorrência'
                          onChange={handleChangeAppointmentRecurrenceEndTime}
                          onKeyPress={handleAppointmentTimeOnKeyPress}
                          onKeyUp={handleAppointmentTimeOnKeyPress}
                          error={getErrorMessageByFieldName('appointmentRecurrenceEndTime')}
                          disabled={areRecurrenceFieldsDisabled || isFieldsDisabled}
                        />
                      </FormGroup>
                    </>
                  )
                }
              </>
            )
            }
          </>

          <FormGroup
            error={getErrorMessageByFieldName('showDocumentValueAndBarCodeNumber')}
            className='toggle'
          >
            <div className='side-label'>Inserir valores?</div>
            <SmallerToggleSwitch
              id={`v-${appointment.Id}`}
              checked={(showDocumentValueAndBarCodeNumber === true)}
              onChange={handleChangeShowDocumentValueAndBarCodeNumber}
              disabled={isFieldsDisabled}
            />
          </FormGroup>

          {
            showDocumentValueAndBarCodeNumber && (
              <>
                <FormGroup
                  error={getErrorMessageByFieldName('documentValue')}
                >
                  <div className='form-label'>Valor</div>
                  <img src={MoneyIcon} alt='time' className="input-icon" />
                  <MyCustomNumberFormat
                    id="document-value"
                    name="document-value"
                    className='document-value'
                    placeholder="Insira o valor R$"
                    value={documentValue}
                    onValueChange={(values, sourceInfo) => {
                      handleChangeDocumentValue(values, sourceInfo);
                    }}
                    error={getErrorMessageByFieldName('documentValue')}
                    disabled={isFieldsDisabled}
                  />
                </FormGroup>

                <FormGroup
                  error={getErrorMessageByFieldName('barCodeNumber')}
                >
                  <div className='form-label'>Código de barras</div>
                  <img src={BarcodeIcon} alt='time' className="input-icon" />
                  <Input
                    type='text'
                    value={barCodeNumber}
                    placeholder='Insira o número'
                    onChange={handleChangeBarCodeNumber}
                    error={getErrorMessageByFieldName('barCodeNumber')}
                    disabled={isFieldsDisabled}
                  />
                </FormGroup>
              </>
            )
          }

          <FormGroup
            error={getErrorMessageByFieldName('appointmentHasAttachment')}
            className='toggle'
          >
            <div className='side-label'>{singleAttachmentLabel}</div>
            <SmallerToggleSwitch
              id={`appointmentHasAttachment-${appointment.Id}`}
              className='mobileToggle'
              checked={(appointmentHasAttachment === true)}
              onChange={handleChangeAppointmentHasAttachment}
              disabled={isFieldsDisabled}
            />
          </FormGroup>

          {
            (
              // ((appointment.AppointmentFile && appointment.AppointmentFile.FileUrl) || mustShowAttachmentFileInput)
              (appointmentHasAttachment)
            ) && (
              <>
                <FormGroup
                  error={getErrorMessageByFieldName('appointmentFiles')}
                  className='input-file-container'
                >
                  {/* <div className='form-label not-input'>{singleAttachmentSubLabel}</div> */}
                  {/* <br />
                  <br /> */}
                  {(!mustShowAttachmentFileInput) && (
                    <>
                      <Button
                        type='button'
                        cancel
                        onClick={() => (window.open(appointment.AppointmentFile.FileUrl, "_blank"))}
                        style={{
                          marginTop: "10px"
                        }}
                      >
                        Visualizar anexo
                      </Button>
                      <button
                        type="button"
                        onClick={(e) => {
                          handleOpenModal(appointment.AppointmentFile, 1);
                        }}
                        className="link-button"
                      >
                        <img src={OrangeTrash} alt='Trash' />
                      </button>
                    </>
                  )}

                  {(mustShowAttachmentFileInput) && (
                    <div
                      className="file-input-container"
                    >
                      <input
                        id='appointment-file'
                        className="inputFile custom-file-input"
                        type="file"
                        name="appointmentFiles"
                        onChange={handleChangeAppointmentFiles}
                        error={getErrorMessageByFieldName('appointmentFiles')}
                        disabled={isFieldsDisabled}
                        style={{ display: "none" }}
                      />

                      <label htmlFor="appointment-file" className={`custom-file-label ${(appointmentFiles && appointmentFiles.name) ? 'selected' : ''}`}>
                        {(appointmentFiles && appointmentFiles.name) ? (
                          <>
                            <img src={FileIcon} alt='file' />
                            <div className="file-name">
                              {appointmentFiles.name}
                            </div>
                          </>
                        ) : (
                          <>
                            Escolher arquivo
                            <img src={OrangeUpload} alt='upload' />
                          </>
                        )}
                      </label>
                      {appointmentFiles && (
                        <button className="remove-file-btn"
                          onClick={() => {
                            setAppointmentFiles(null);
                          }}>
                          <img src={Trash} alt='Trash' />
                        </button>
                      )}
                    </div>
                  )}
                </FormGroup>

                {
                  (isUserAllowedMultipleAttachments) && (
                    <>
                      <FormGroup
                        error={getErrorMessageByFieldName('appointmentFiles2')}
                        className='input-file-container'
                      >
                        {(!mustShowAttachmentFileInput2) && (
                          <>
                            <Button
                              type='button'
                              cancel
                              onClick={() => (window.open(appointment.AppointmentFile2.FileUrl, "_blank"))}
                              style={{
                                marginTop: "10px"
                              }}
                            >
                              Visualizar anexo 2
                            </Button>
                            <button
                              type="button"
                              onClick={(e) => {
                                handleOpenModal(appointment.AppointmentFile2, 2);
                              }}
                              className="link-button"
                            >
                              <img src={OrangeTrash} alt='Trash' />
                            </button>
                          </>
                        )}

                        {(mustShowAttachmentFileInput2) && (
                          <>
                            {/* <input
                              id='appointment-file2'
                              className="inputFile custom-file-input"
                              type="file"
                              name="appointmentFiles"
                              onChange={handleChangeAppointmentFiles2}
                              error={getErrorMessageByFieldName('appointmentFiles2')}
                              disabled={isFieldsDisabled}
                            /> */}

                            <div
                              className="file-input-container"
                            >
                              <input
                                id='appointment-file2'
                                className="inputFile custom-file-input"
                                type="file"
                                name="appointmentFiles"
                                onChange={handleChangeAppointmentFiles2}
                                error={getErrorMessageByFieldName('appointmentFiles2')}
                                disabled={isFieldsDisabled}
                                style={{ display: "none" }}
                              />
                              <label htmlFor="appointment-file2" className={`custom-file-label ${(appointmentFiles2 && appointmentFiles2.name) ? 'selected' : ''}`}>
                                {(appointmentFiles2 && appointmentFiles2.name) ? (
                                  <>
                                    <img src={FileIcon} alt='file' />
                                    <div className="file-name">
                                      {appointmentFiles2.name}
                                    </div>
                                  </>
                                ) : (
                                  <>
                                    Escolher arquivo
                                    <img src={OrangeUpload} alt='upload' />
                                  </>
                                )}
                              </label>
                              {appointmentFiles2 && (
                                <button className="remove-file-btn" onClick={() => {
                                  setAppointmentFiles2(null);
                                }}>
                                  <img src={Trash} alt='Trash' />
                                </button>
                              )}
                            </div>
                          </>

                        )}
                      </FormGroup>

                      <FormGroup
                        error={getErrorMessageByFieldName('appointmentFiles3')}
                        className='input-file-container'
                      >
                        {(!mustShowAttachmentFileInput3) && (
                          <>
                            <Button
                              type='button'
                              cancel
                              onClick={() => (window.open(appointment.AppointmentFile3.FileUrl, "_blank"))}
                              style={{
                                marginTop: "10px"
                              }}
                            >
                              Visualizar anexo 3
                            </Button>
                            <button
                              type="button"
                              onClick={(e) => {
                                handleOpenModal(appointment.AppointmentFile3, 3);
                              }}
                              className="link-button"
                            >
                              <img src={OrangeTrash} alt='Trash' />
                            </button>
                          </>
                        )}

                        {(mustShowAttachmentFileInput3) && (
                          <>
                            {/* <input
                              id='appointment-file3'
                              className="inputFile custom-file-input"
                              type="file"
                              name="appointmentFiles"
                              onChange={handleChangeAppointmentFiles3}
                              error={getErrorMessageByFieldName('appointmentFiles3')}
                              disabled={isFieldsDisabled}
                            /> */}
                            {/* <label htmlFor='appointment-file'></label> */}

                            <div
                              className="file-input-container"
                            >
                              <input
                                id='appointment-file3'
                                className="inputFile custom-file-input"
                                type="file"
                                name="appointmentFiles"
                                onChange={handleChangeAppointmentFiles3}
                                error={getErrorMessageByFieldName('appointmentFiles3')}
                                disabled={isFieldsDisabled}
                                style={{ display: "none" }}
                              />
                              <label htmlFor="appointment-file3" className={`custom-file-label ${(appointmentFiles3 && appointmentFiles3.name) ? 'selected' : ''}`}>
                                {(appointmentFiles3 && appointmentFiles3.name) ? (
                                  <>
                                    <img src={FileIcon} alt='file' />
                                    <div className="file-name">
                                      {appointmentFiles3.name}
                                    </div>
                                  </>
                                ) : (
                                  <>
                                    Escolher arquivo
                                    <img src={OrangeUpload} alt='upload' />
                                  </>
                                )}
                              </label>
                              {appointmentFiles3 && (
                                <button className="remove-file-btn" onClick={() => {
                                  setAppointmentFiles3(null);
                                }}>
                                  <img src={Trash} alt='Trash' />
                                </button>
                              )}
                            </div>
                          </>
                        )}
                      </FormGroup>

                      <FormGroup
                        error={getErrorMessageByFieldName('appointmentFiles4')}
                        className='input-file-container'
                      >
                        {(!mustShowAttachmentFileInput4) && (
                          <>
                            <Button
                              type='button'
                              cancel
                              onClick={() => (window.open(appointment.AppointmentFile4.FileUrl, "_blank"))}
                              style={{
                                marginTop: "10px"
                              }}
                            >
                              Visualizar anexo 4
                            </Button>
                            <button
                              type="button"
                              onClick={(e) => {
                                handleOpenModal(appointment.AppointmentFile4, 4);
                              }}
                              className="link-button"
                            >
                              <img src={OrangeTrash} alt='Trash' />
                            </button>
                          </>
                        )}

                        {(mustShowAttachmentFileInput4) && (
                          <>
                            {/* <input
                              id='appointment-file4'
                              className="inputFile custom-file-input"
                              type="file"
                              name="appointmentFiles"
                              onChange={handleChangeAppointmentFiles4}
                              error={getErrorMessageByFieldName('appointmentFiles4')}
                              disabled={isFieldsDisabled}
                            /> */}
                            {/* <label htmlFor='appointment-file'></label> */}

                            <div
                              className="file-input-container"
                            >
                              <input
                                id='appointment-file4'
                                className="inputFile custom-file-input"
                                type="file"
                                name="appointmentFiles"
                                onChange={handleChangeAppointmentFiles4}
                                error={getErrorMessageByFieldName('appointmentFiles4')}
                                disabled={isFieldsDisabled}
                                style={{ display: "none" }}
                              />
                              <label htmlFor="appointment-file4" className={`custom-file-label ${(appointmentFiles4 && appointmentFiles4.name) ? 'selected' : ''}`}>
                                {(appointmentFiles4 && appointmentFiles4.name) ? (
                                  <>
                                    <img src={FileIcon} alt='file' />
                                    <div className="file-name">
                                      {appointmentFiles4.name}
                                    </div>
                                  </>
                                ) : (
                                  <>
                                    Escolher arquivo
                                    <img src={OrangeUpload} alt='upload' />
                                  </>
                                )}
                              </label>
                              {appointmentFiles4 && (
                                <button className="remove-file-btn" onClick={() => {
                                  setAppointmentFiles4(null);
                                }}>
                                  <img src={Trash} alt='Trash' />
                                </button>
                              )}
                            </div>
                          </>
                        )}
                      </FormGroup>

                      <FormGroup
                        error={getErrorMessageByFieldName('appointmentFiles5')}
                        className='input-file-container'
                      >
                        {(!mustShowAttachmentFileInput5) && (
                          <>
                            <Button
                              type='button'
                              cancel
                              onClick={() => (window.open(appointment.AppointmentFile5.FileUrl, "_blank"))}
                              style={{
                                marginTop: "10px"
                              }}
                            >
                              Visualizar anexo 5
                            </Button>
                            <button
                              type="button"
                              onClick={(e) => {
                                handleOpenModal(appointment.AppointmentFile5, 5);
                              }}
                              className="link-button"
                            >
                              <img src={OrangeTrash} alt='Trash' />
                            </button>
                          </>
                        )}

                        {(mustShowAttachmentFileInput5) && (
                          <>
                            {/* <input
                              id='appointment-file5'
                              className="inputFile custom-file-input"
                              type="file"
                              name="appointmentFiles"
                              onChange={handleChangeAppointmentFiles5}
                              error={getErrorMessageByFieldName('appointmentFiles5')}
                              disabled={isFieldsDisabled}
                            /> */}
                            {/* <label htmlFor='appointment-file'></label> */}
                            <div
                              className="file-input-container"
                            >
                              <input
                                id='appointment-file5'
                                className="inputFile custom-file-input"
                                type="file"
                                name="appointmentFiles"
                                onChange={handleChangeAppointmentFiles5}
                                error={getErrorMessageByFieldName('appointmentFiles5')}
                                disabled={isFieldsDisabled}
                                style={{ display: "none" }}
                              />
                              <label htmlFor="appointment-file5" className={`custom-file-label ${(appointmentFiles5 && appointmentFiles5.name) ? 'selected' : ''}`}>
                                {(appointmentFiles5 && appointmentFiles5.name) ? (
                                  <>
                                    <img src={FileIcon} alt='file' />
                                    <div className="file-name">
                                      {appointmentFiles5.name}
                                    </div>
                                  </>
                                ) : (
                                  <>
                                    Escolher arquivo
                                    <img src={OrangeUpload} alt='upload' />
                                  </>
                                )}
                              </label>
                              {appointmentFiles5 && (
                                <button className="remove-file-btn" onClick={() => {
                                  setAppointmentFiles5(null);
                                }}>
                                  <img src={Trash} alt='Trash' />
                                </button>
                              )}
                            </div>
                          </>
                        )}
                      </FormGroup>
                    </>
                  )
                }
              </>
            )
          }

          {(isSharedAppointment) && (
            <ButtonContainer>
              <Button
                secondary
                type='button'
                onClick={(e) => {
                  handleRemoveApproval();
                }}
              >
                Sair do compromisso
              </Button>
            </ButtonContainer>
          )}

          {(!isSharedAppointment) && (
            <ButtonContainer>
              <Button
                type='submit'
                disabled={((!isFormValid()))}
              >
                {buttonLabel}
              </Button>
            </ButtonContainer>
          )}

          {
            (appointment.Id !== 0 && (!isSharedAppointment)) && (
              <>
                {/* <ButtonContainer>
                  <Button
                    type='button'
                    secondary
                    onClick={(e) => {
                      if (!appointment.AppointmentHasRecurrences) {
                        handleOpenDeleteModal(e, appointment);
                      }
                      else {
                        handleOpenModalOptions(e, appointment);
                      }
                    }}
                    disabled={isFieldsDisabled}
                  >
                    Excluir compromisso
                  </Button>
                </ButtonContainer> */}
                {/* <ButtonContainer>
                  <Button
                    type='button'
                    cancel
                    onClick={() => window.open(`/`, '_self')}
                  >
                    Cancelar
                  </Button>
                </ButtonContainer> */}
              </>
            )
          }

          {/* {
            (appointment.Id === 0) && (
              <>
                <ButtonContainer>
                  <Button
                    type='button'
                    secondary
                    onClick={() => window.open(`/`, '_self')}
                  >
                    Cancelar
                  </Button>
                </ButtonContainer>
              </>
            )
          } */}
          <div className="bottom-space"></div>
        </Form >
      </div>
    </>
  );
}