import BigLogo from '../../assets/icons/menu/big-logo.svg'
import Profile from '../../assets/icons/profile.svg'
import NotificationIcon from '../../assets/icons/notification.svg'
import React, { useState, useEffect } from 'react';
import UsersService from '../../services/UsersService';
import Config from '../../config/environments/local';
import { localGet } from "../../lib/session";

export default function BasicHeader({ NotificationsCount }) {
  const [sessionObject] = useState(localGet(Config.SESSION_KEY));
  const [user, setUser] = useState({});

  useEffect(() => {
    async function getUser() {
      try {

        const response = await new UsersService(Config.API_BASE_URL).GetById(sessionObject.userId);
        console.log(response.Result.ProfilePictureFileUrl);

        setUser(response.Result);
      } catch (error) {
        console.log('error ', error);
      } finally {
      }
    }

    getUser();
  }, [user, sessionObject.userId]);

  return (
    <>
      <div className='basic-header-container'>
        <div className='profilepicture-container'
          onClick={(e) => {
            window.open('/profile', '_self');
          }}
        >
          {(user) && (
            <img src={user.ProfilePictureFileUrl ? user.ProfilePictureFileUrl : Profile} alt='profile' />
          )}
          {/* <img src={Profile} alt='profile' /> */}
          {/* <img src='https://kroonar-utility.s3.amazonaws.com/16/telembro-file-5fe69002-4b03-4c2f-8a09-8c8ad925f04acropped-image.b3c2eca5-6233-4ab6-984a-f5d4d17e48a7.jpg?AWSAccessKeyId=AKIAIIU5EPPM4I4KYKKA&Expires=2043614755&Signature=rqjzHjfM3B0KnO7VsOZBpCjNcRs%3D' alt='profile' /> */}
        </div>

        <div className='logo-container'
          onClick={(e) => {
            window.open('/', '_self');
          }}
        >
          <img src={BigLogo} alt='logo' />
        </div>

        <div className='notifications-container'
          onClick={(e) => {
            window.open('/notification-center', '_self');
          }}
        >
          <div className='icon-container'>
            <img src={NotificationIcon} alt='notification' />
          </div>

          {(NotificationsCount > 0) && (
            <div className='notifications-count-container'>
              {NotificationsCount > 1 ? `${NotificationsCount > 9 ? '9+' : NotificationsCount - 1}+` : NotificationsCount}
            </div>
          )}

        </div>

      </div>
    </>
  );
}