// import CalendarImage from '../../assets/images/new-layout/calendar_some_some.svg'
import KeyIcon from '../../assets/icons/key-icon.svg'
import RemoveAccount from '../../assets/images/remove-account.png'
import Logout from '../../assets/images/logout.png'
import RedTrash from '../../assets/icons/red-trash.svg'
import OrangeLogout from '../../assets/icons/orange-logout.svg'
import ProfileImage from '../../assets/icons/profile.svg'
// import ShieldPerson from '../../assets/icons/shield_person.svg'
import GoBackHeader from "../../components/GoBackHeader";
import FormGroup from "../../components/FormGroup";
import Input from "../../components/Input";
import Select from "../../components/Select";
import { localGet, localRemove } from "../../lib/session";
import Loader from '../../components/Loader';
import InputMask from 'react-input-mask';
import React, {
  useState
  , useEffect
  , useCallback
} from 'react';
import UsersService from '../../services/UsersService';
import TypesService from '../../services/TypesService';
// import Loader from '../../components/Loader';
import Config from '../../config/environments/local';
import useErrors from "../../hooks/useErrors";
import GenericModal from '../../components/GenericModal';

export default function Profile() {
  // const [isLoading, setIsLoading] = useState(true);
  const [sessionObject, setSessionObject] = useState(localGet(Config.SESSION_KEY));
  const [user, setUser] = useState({});
  const [name, setName] = useState(user.Name ? user.Name : '');
  const [email, setEmail] = useState(user.Email ? user.Email : '');
  const [cellPhone, setCellPhone] = useState(user.CellPhone ? user.CellPhone : '');
  const [stateId, setStateId] = useState(user.StateId ? user.StateId : '');
  const [cityId, setCityId] = useState(user.CityId ? user.CityId : '');
  const [isLoading, setIsLoading] = useState(false);
  const [isCancelUserRegistrationModalOpen, setIsCancelUserRegistrationModalOpen] = useState(false);
  const [isLogoutModalOpen, setIsLogoutModalOpen] = useState(false);

  const getUser = useCallback(async () => {
    if (!sessionObject) {
      return null;
    }

    try {
      // setIsLoading(true);

      // const response = await new UsersService(Config.API_BASE_URL).GetById(sessionObject.userId);
      const response = await new UsersService(Config.API_BASE_URL).GetById(sessionObject.userId);

      setUser(response.Result);

      setName(response.Result.Name);
      setEmail(response.Result.Email);
      setCellPhone(response.Result.CellPhone);
      setStateId(response.Result.StateId);
      setCityId(response.Result.CityId);

    } catch (error) {
      console.log('error ', error);
    } finally {
      // setIsLoading(false);
    }
  }, [sessionObject]);

  useEffect(() => {
    getUser();
  }, [getUser]);

  const updateUser = async () => {
    if (!sessionObject) {
      return null;
    }

    if (
      !name ||
      !email
    ) {
      alert("Nome e/ou e-mail não podem ser vazios");
      return false;
    }

    try {
      setIsLoading(true);

      const response = await new UsersService(Config.API_BASE_URL).Update({
        Id: sessionObject.userId,
        Name: name,
        Email: email,
        CellPhone: cellPhone,
        StateId: stateId,
        CityId: cityId,
      });

      console.log(response);

    } catch (error) {
      setIsLoading(false);

      console.log('error ', error);
    } finally {
      setIsLoading(false);
    }
  };

  async function handleCancelUserRegistration() {
    try {
      setIsLoading(true);

      const deleteResponse = await new UsersService(Config.API_BASE_URL).Delete(
        {
          Id: sessionObject.userId,
        }
      );

      if (deleteResponse.Result) {
        localRemove(Config.SESSION_KEY);
        setSessionObject(null);
        window.open('/', '_self');
      }

    } catch (error) {
      console.log('error ', error);
    } finally {
      setIsLoading(false);
    }
  }

  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);


  useEffect(() => {
    async function GetStates() {
      try {
        setIsLoading(true);

        const response = await new TypesService(Config.API_BASE_URL).GetStates();

        setStates(response.Result);
      } catch (error) {
        console.log('error ', error);
      } finally {
        setIsLoading(false);
      }
    }

    if (states.length === 0) {
      GetStates();
    }
  }, [states]);

  useEffect(() => {
    async function GetCities() {
      try {
        setIsLoading(true);

        const response = await new TypesService(Config.API_BASE_URL).GetCities();

        setCities(response.Result);
      } catch (error) {
        console.log('error ', error);
      } finally {
        setIsLoading(false);
      }
    }

    if (cities.length === 0) {
      GetCities();
    }
  }, [cities]);

  const {
    // errors,
    // setError,
    // removeError,
    getErrorMessageByFieldName,
  } = useErrors();

  function handleLogout() {
    localRemove(Config.SESSION_KEY);
    setSessionObject(null);
    window.open('/', '_self');
  }

  return (
    <>
      <GenericModal
        title=''
        body={[
          <>
            <div className='center-image'>
              <img src={RemoveAccount} alt='remove' />
            </div>
            <div className='modal-body-title'>
              Excluir conta
            </div>
            <div className='modal-body-text'>
              Ao excluir sua conta todos os seus dados serão perdidos e não será possível recuperá-los. Você tem certeza?
            </div>
            <div className='button-container'>
              <button
                type="button"
                onClick={(e) => {
                  handleCancelUserRegistration();
                }}
                className="danger-danger"
              >
                Excluir minha conta
              </button>
            </div>
            <div className='button-container'>
              <button
                type="button"
                onClick={(e) => {
                  setIsCancelUserRegistrationModalOpen(false);
                }}
                className="secondary"
              >
                Cancelar
              </button>
            </div>
          </>
        ]}
        isVisible={isCancelUserRegistrationModalOpen}
        onConfirm={handleCancelUserRegistration}
        onCancel={(e) => {
          setIsCancelUserRegistrationModalOpen(false);
        }}
      />

      <GenericModal
        title=''
        body={[
          <>
            <div className='center-image'>
              <img src={Logout} alt='remove' />
            </div>
            <div className='modal-body-title'>
              Fazer logout
            </div>
            <div className='modal-body-text'>
              Você tem certeza que deseja sair do app?
            </div>
            <div className='button-container'>
              <button
                type="button"
                onClick={(e) => {
                  handleLogout();
                }}
                className="button"
              >
                Fazer logout
              </button>
            </div>
            <div className='button-container'>
              <button
                type="button"
                onClick={(e) => {
                  setIsLogoutModalOpen(false);
                }}
                className="secondary"
              >
                Cancelar
              </button>
            </div>
          </>
        ]}
        isVisible={isLogoutModalOpen}
        onConfirm={handleLogout}
        onCancel={(e) => {
          setIsLogoutModalOpen(false);
        }}
      />
      <GoBackHeader
        backAction={"/"}
        title={"Meu perfil"}
        mustShowInfoIcon={false}
        // actionText='Salvar'
        // onActionExecute={() => {
        //   // save user profile code
        // }}
        rightAlignContent={[
          <div className='save-user-profile-container'
            onClick={(e) => {
              updateUser();
            }}
          >
            Salvar
          </div>
        ]}
      />
      <div className='basic-container'>
        <Loader isLoading={isLoading} />

        {(user && user.Name) && (
          <>
            <div className='profile-image-container'>
              <img src={user.ProfilePictureFileUrl ? user.ProfilePictureFileUrl : ProfileImage} alt='profile' />
            </div>
            <div className='change-photo'>
              <a href="/change-picture"
                style={{
                  margin: 'auto'
                }}
              >Alterar foto</a>
            </div>
            <form noValidate className="form">
              <FormGroup
                error={getErrorMessageByFieldName('name')}
              >
                <div className='form-label'>Nome completo</div>
                <Input
                  type='text'
                  value={name}
                  placeholder='Insira o seu nome aqui'
                  onChange={(e) => {
                    setName(e.target.value);
                  }}
                  error={getErrorMessageByFieldName('name')}
                />
              </FormGroup>
              <FormGroup
                error={getErrorMessageByFieldName('email')}
              >
                <div className='form-label'>E-mail</div>
                <Input
                  type='text'
                  value={email}
                  placeholder='Insira o seu e-mail'
                  onChange={(e) => {
                    setEmail(e.target.value);
                  }}
                  error={getErrorMessageByFieldName('email')}
                />
              </FormGroup>
              <FormGroup
                error={getErrorMessageByFieldName('cellPhone')}
              >
                <div className='form-label'>Celular</div>
                <InputMask
                  mask='(99) 99999-9999' // Brazilian cellphone format
                  value={cellPhone}
                  onChange={(e) => setCellPhone(e.target.value)}
                  maskChar={null} // Optional: Hides the mask characters when the input is empty
                >
                  {(inputProps) => (
                    <Input
                      {...inputProps}
                      type='text'
                      placeholder='Insira o seu celular'
                      error={getErrorMessageByFieldName('cellPhone')}
                    />
                  )}
                </InputMask>
              </FormGroup>
              <div className='form-row-group'>
                <FormGroup
                  error={getErrorMessageByFieldName('stateId')}
                  className='small-select'
                >
                  <div className='form-label'>Estado</div>
                  <Select
                    value={stateId}
                    onChange={(e) => {
                      setStateId(e.target.value);
                    }}
                    error={getErrorMessageByFieldName('stateId')}
                    placeholder='Selecione o estado'
                    className="small"
                  >
                    <option value="">Selecione</option>
                    {states && (
                      states.map((state) => (
                        <option
                          key={state.Id}
                          value={state.Id}
                        >
                          {state.Name}</option>
                      )))}
                  </Select>
                </FormGroup>
                <FormGroup
                  error={getErrorMessageByFieldName('cityId')}
                  className='small-select'
                >
                  <div className='form-label'>Cidade</div>
                  <Select
                    value={cityId}
                    onChange={(e) => {
                      setCityId(e.target.value);
                    }}
                    error={getErrorMessageByFieldName('cityId')}
                    placeholder='Selecione a cidade'
                    className="small"
                  >
                    <option value="">Selecione</option>
                    {cities && (
                      cities.map((city) => (
                        <option key={city.Id} value={city.Id}>{city.Name}</option>
                      )))}
                  </Select>
                </FormGroup>
              </div>
            </form>
            <div className='button-container'>
              <button
                type="button"
                onClick={(e) => {
                  window.open('/change-password', '_self');
                }}
                className="secondary"
              >
                Alterar minha senha
                <img src={KeyIcon} alt='Trash' />
              </button>
            </div>
            <div style={{
              height: '128px'
            }}>
            </div>
            <div className='button-container'>
              <button
                type="button"
                onClick={(e) => {
                  setIsCancelUserRegistrationModalOpen(true);
                }}
                className="danger small"
              >
                Excluir conta
                <img src={RedTrash} alt='Trash' />
              </button>
              <button
                type="button"
                onClick={(e) => {
                  setIsLogoutModalOpen(true);
                }}
                className="secondary small"
              >
                Fazer logout
                <img src={OrangeLogout} alt='logout' />
              </button>
            </div>
          </>
        )}
      </div>
    </>
  )
};