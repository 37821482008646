// import ShieldI from '../../assets/icons/shield_i.svg'
import {
  useState,
  useEffect,
  useCallback,
} from "react";
import FormGroup from "../../components/FormGroup";
import { localGet } from '../../lib/session';
import Config from "../../config/environments/local";
import PushNotificationsService from "../../services/PushNotificationsService";
import Loader from "../../components/Loader";
// import OneSignal from 'react-onesignal';
import GoBackHeader from "../../components/GoBackHeader";
import SmallerToggleSwitch from "../../components/SmallerToggleSwitch";
import Notifications from '../../assets/images/notifications.png';

// import Warning from '../../assets/icons/warning.svg';

export default function Configurations() {

  const [sessionObject] = useState(localGet(Config.SESSION_KEY));
  const [pushNotificationsConfigurations, setPushNotificationsConfigurations] = useState({});
  const [isAllowedGlobalPushNotifications, setIsAllowedGlobalPushNotifications] = useState(pushNotificationsConfigurations.AllowInGeneral ?? false);
  const [isAllowedForAppointmentsPushNotifications, setIsAllowedForAppointmentsPushNotifications] = useState(pushNotificationsConfigurations.AllowForAllAppointments ?? false);
  const [allow1212, setAllow1212] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  // const [userSubscriptionId] = useState(localGet('onesignalsubscriptionid'));
  // const [externalId] = useState(sessionObject.pushNotificationSubscriptionId);

  const getPushNotificationsConfigurations = useCallback(async () => {
    try {
      setIsLoading(true);
      const getConfigurationsResponse = await new PushNotificationsService(Config.API_BASE_URL).GetConfigurations({
        UserId: sessionObject.userId,
      });

      setPushNotificationsConfigurations(getConfigurationsResponse.Result);

      setIsAllowedGlobalPushNotifications(getConfigurationsResponse.Result.AllowInGeneral);
      setIsAllowedForAppointmentsPushNotifications(getConfigurationsResponse.Result.AllowForAllAppointments);

    } catch (error) {
      console.log('error ', error);
    } finally {
      setIsLoading(false);
    }
  }, [sessionObject.userId]);

  useEffect(() => {
    getPushNotificationsConfigurations();
  }, [getPushNotificationsConfigurations]);

  async function ChangeIsAllowedGlobalPushNotifications(IsAllowedGlobalPushNotifications) {

    if (IsAllowedGlobalPushNotifications) {
      await new PushNotificationsService(Config.API_BASE_URL).ActivateGlobal({
        UserId: sessionObject.userId,
      });
    } else {
      await new PushNotificationsService(Config.API_BASE_URL).DeactivateGlobal({
        UserId: sessionObject.userId,
      });
    }

    await getPushNotificationsConfigurations();

  };
  async function ChangeIsAllowedForAppointmentsPushNotifications(IsAllowedGlobalPushNotifications) {

    if (IsAllowedGlobalPushNotifications) {
      await new PushNotificationsService(Config.API_BASE_URL).ActivateAppointments({
        UserId: sessionObject.userId,
      });
    } else {
      await new PushNotificationsService(Config.API_BASE_URL).DeactivateAppointments({
        UserId: sessionObject.userId,
      });
    }

    await getPushNotificationsConfigurations();
  };

  function handleChangeIsAllowedGlobalPushNotifications(e) {

    const inputValue = e.target.checked;
    const IsAllowedGlobalPushNotifications = (inputValue === true);
    ChangeIsAllowedGlobalPushNotifications(IsAllowedGlobalPushNotifications)

    setIsAllowedGlobalPushNotifications(IsAllowedGlobalPushNotifications);

    if (!IsAllowedGlobalPushNotifications) {
      ChangeIsAllowedForAppointmentsPushNotifications(IsAllowedGlobalPushNotifications)
      setIsAllowedForAppointmentsPushNotifications(IsAllowedGlobalPushNotifications);
    }
  }

  function handleChangeIsAllowedForAppointmentsPushNotifications(e) {
    const inputValue = e.target.checked;
    const IsAllowedForAppointmentsPushNotifications = (inputValue === true);

    ChangeIsAllowedForAppointmentsPushNotifications(IsAllowedForAppointmentsPushNotifications)

    setIsAllowedForAppointmentsPushNotifications(IsAllowedForAppointmentsPushNotifications);
  }

  return (
    <>
      <Loader isLoading={isLoading} />
      <GoBackHeader
        backAction={"/"}
        title={"Configurações de notificação"}
        mustShowInfoIcon={false}
      />
      <div className='basic-container'>
        <>
          <div className='image-container'>
            <img src={Notifications} alt='logo' />
            {/* <img src={NoPendingItems} alt='logo' /> */}
          </div>
          <FormGroup
            className='toggle bordered'
          >
            <div className='side-label'>Permitir notificações</div>
            {/* <div
                  className='toggleWrapper'
                >
                  <input
                    id={`g-allow`}
                    type="checkbox"
                    className='mobileToggle'
                    key={`g-allow`}
                    checked={(isAllowedGlobalPushNotifications === true)}
                    onChange={handleChangeIsAllowedGlobalPushNotifications}
                  />
                  <label htmlFor={`g-allow`}></label>
                </div> */}
            <SmallerToggleSwitch
              id={`g-allow`}
              type="checkbox"
              className='mobileToggle'
              key={`g-allow`}
              checked={(isAllowedGlobalPushNotifications === true)}
              onChange={handleChangeIsAllowedGlobalPushNotifications}
            />
          </FormGroup>

          <FormGroup
            className='toggle bordered'
          >
            <div className='side-label'>Habilitado por padrão, por compromisso</div>
            <SmallerToggleSwitch
              id={`p-allow`}
              type="checkbox"
              className='mobileToggle'
              key={`p-allow`}
              checked={(isAllowedForAppointmentsPushNotifications === true)}
              onChange={handleChangeIsAllowedForAppointmentsPushNotifications}
            />
          </FormGroup>

          <FormGroup
            className='toggle bordered'
          >
            <div className='side-label'>E-mail de resumo diário</div>
            <SmallerToggleSwitch
              id={`z-allow`}
              type="checkbox"
              className='mobileToggle'
              key={`z-allow`}
              checked={(allow1212 === true)}
              onChange={() => {
                setAllow1212(!allow1212);
              }}
            />
          </FormGroup>
        </>
        {/* Push notifications são notificações rápidas que o TeLembro enviará na tela do seu dispositivo para te ajudar a não esquecer nenhum compromisso, dentre outras facilidades!
        <br />
        <br />
        As notificações que você poderá receber são sobre:
        <br />
        <br />
        1. Seus compromissos e os lembretes dos mesmos;
        <br />
        <br />
        2. Novos compromissos compartilhados com você;
        <br />
        <br />
        3. Mensagens recebidas dentro de compromissos compartilhados com você.
        <br />
        <br /> */}
        <div className='warning-container2'
          style={{
            marginTop: '16px',
            marginBottom: '123px',
          }}
        >
          <div className='warning-container2-title'>
            As notificações que você pode receber são sobre:
          </div>
          <div className='warning-container2-content'>
            <ul>
              <li>
                Seus compromissos e os lembretes dos mesmos;
              </li>
              <li>
                Novos compromissos compartilhados com você;
              </li>
              <li>
                Mensagens recebidas dentro de compromissos compartilhados com você.
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div
        className="button-container"
      >
        <button
          type='button'
          className='button secondary'
          onClick={() => window.open('/', '_self')}
          onKeyDown={() => window.open('/', '_self')}
        >
          Voltar
        </button>

      </div>
    </>
  )
};