import FaleConosco from '../../assets/images/fale-conosco.png'
// import ShieldI from '../../assets/icons/shield_i.svg'
// import ShieldPerson from '../../assets/icons/shield_person.svg'
import GoBackHeader from "../../components/GoBackHeader";
import {
  useState,
  // useEffect,
  // useCallback,
} from "react";
import Loader from "../../components/Loader";

export default function TalkToUs() {
  // const [isLoading, setIsLoading] = useState(false);
  const [isLoading] = useState(false);

  return (
    <>
      <Loader isLoading={isLoading} />
      <GoBackHeader
        backAction={"/"}
        title={"Fale conosco"}
        mustShowInfoIcon={false}
      />
      <div className='basic-container'>
        <>
          <div className='image-container'>
            <img src={FaleConosco} alt='logo' />
          </div>

        </>
        <div className="regular-title">
          <br />
          <br />
          Como podemos ajudar?
          <br />
          <br />
        </div>
        <div className="regular-text">
          Para dúvidas gerais envie um e-mail para:
        </div>

        <div
          className=""
        >
          <a
            href='#t'
            className='link'
            onClick={() => window.location.href = "mailto:info@telembro.com"}
            onKeyDown={() => window.location.href = "mailto:info@telembro.com"}
          >
            info@telembro.com
          </a>
          <br />
          <br />
          <br />
        </div>

        <div className="regular-text">
          Fale com o DPO
        </div>

        <div
          className=""
        >
          <a
            href='#t'
            className='link'
            onClick={() => window.location.href = "mailto:telembro.dpo@gmail.com"}
            onKeyDown={() => window.location.href = "mailto:telembro.dpo@gmail.com"}
          >
            telembro.dpo@gmail.com
          </a>
        </div>

      </div>
    </>
  )
};