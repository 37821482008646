/* eslint-disable jsx-a11y/anchor-is-valid */
import PropTypes from 'prop-types';
import ReactDOM from "react-dom";
import { Overlay } from './styles';
// import Button from '../Button';
import { localGet } from "../../lib/session";
import { useState, useEffect, useCallback } from "react";
import Config from "../../config/environments/local";
import UsersService from '../../services/UsersService';
import GenericModal from '../GenericModal';
import CloseButton from '../../assets/icons/menu/close.svg'
import AccountCircle from '../../assets/icons/menu/account_circle.svg'
import ArrowDown from '../../assets/icons/menu/arrow_down.svg'
import ArrowUp from '../../assets/icons/menu/arrow_up.svg'
import Logo from '../../assets/icons/menu/logotipo.svg'
import Facebook from '../../assets/icons/menu/facebook.svg'
import Instagram from '../../assets/icons/menu/instagram.svg'
import Linkedin from '../../assets/icons/menu/linkedin.svg'
import Youtube from '../../assets/icons/menu/youtube.svg'
import Profile from '../../assets/icons/profile.svg'
import Funcionalidades from '../../assets/icons/menu/funcionalidades.svg'
import NovoCompromisso from '../../assets/icons/menu/novocompromisso.svg'
import Compartilhamentos from '../../assets/icons/menu/compartilhamentos.svg'
import Relatorios from '../../assets/icons/menu/relatorios.svg'
import Configuracoes from '../../assets/icons/menu/configuracoes.svg'
import GerenciarTipos from '../../assets/icons/menu/gerenciartipos.svg'
import Notificacoes from '../../assets/icons/menu/notificacoes.svg'
import FusoHorario from '../../assets/icons/menu/fusohorario.svg'
import Ajuda from '../../assets/icons/menu/ajuda.svg'
import Tutoriais from '../../assets/icons/menu/tutoriais.svg'
import FaleConosco from '../../assets/icons/menu/faleconosco.svg'
import Sobre from '../../assets/icons/menu/sobre.svg'
import Version from '../../assets/images/version.png'

export default function NewSideBar({ isVisible, onClose, onLogOut, onOpenModalCancelUserRegistration, showUserLink }) {
  const [sessionObject] = useState(localGet(Config.SESSION_KEY));
  const [openMenus, setOpenMenus] = useState({});
  const [user, setUser] = useState({});
  const [isVersionModalOpen, setIsVersionModalOpen] = useState(false);

  const getUser = useCallback(async () => {
    if (!sessionObject) {
      return null;
    }

    try {
      // setIsLoading(true);

      // const response = await new UsersService(Config.API_BASE_URL).GetById(sessionObject.userId);
      const response = await new UsersService(Config.API_BASE_URL).GetById(sessionObject.userId);

      setUser(response.Result);

    } catch (error) {
      console.log('error ', error);
    } finally {
      // setIsLoading(false);
    }
  }, [sessionObject]);

  useEffect(() => {
    getUser();
  }, [getUser]);

  const toggleMenu = (menuName) => {
    setOpenMenus(prev => ({
      ...prev,
      [menuName]: !prev[menuName]
    }));
  };


  if (!isVisible) {
    return null;
  }

  return ReactDOM.createPortal(
    <>
      <Overlay>
        <div className="new-sidenav">
          <div className='close-button-container'>
            <button
              onClick={onClose}
            >
              <img src={CloseButton} alt='caret' />
            </button>
          </div>
          <div className='name-container'>
            <div className='image'>
              {(user) && (
                <img src={user.ProfilePictureFileUrl ? user.ProfilePictureFileUrl : Profile} alt='profile' />
              )}
              {/* <img src='https://kroonar-utility.s3.amazonaws.com/16/telembro-file-5fe69002-4b03-4c2f-8a09-8c8ad925f04acropped-image.b3c2eca5-6233-4ab6-984a-f5d4d17e48a7.jpg?AWSAccessKeyId=AKIAIIU5EPPM4I4KYKKA&Expires=2043614755&Signature=rqjzHjfM3B0KnO7VsOZBpCjNcRs%3D' alt='profile' /> */}
            </div>
            <div className='name'>{sessionObject.userName}</div>
          </div>
          <div className='menu-content'>
            <div className='menu-options-container'>
              <div className='menu-option'
                onClick={(e) => {
                  window.open('/profile', '_self');
                }}
              >
                <div className='icon'>
                  <img src={AccountCircle} alt='caret' />
                </div>
                <div className='option-name'>
                  Perfil
                </div>
              </div>
              <div className='menu-option'
                onClick={() => toggleMenu('Funcionalidades')}
              >
                <div className='icon'>
                  <img src={Funcionalidades} alt='caret' />
                </div>
                <div className='option-name'>
                  Funcionalidades
                </div>
                <div className='arrow'>
                  <img src={openMenus['Funcionalidades'] ? ArrowUp : ArrowDown} alt='Toggle Submenu' />
                </div>
              </div>
              {openMenus['Funcionalidades'] && (
                <div className='submenu'>
                  <div className='submenu-item'
                    onClick={() => {
                      window.open('/new', '_self');
                    }}
                  >
                    <div className='icon'>
                      <img src={NovoCompromisso} alt='caret' />
                    </div>
                    <div className='option-name'>
                      Novo compromisso
                    </div>
                  </div>
                  <div className='submenu-item'
                    onClick={() => {
                      window.open('/shared-management', '_self');
                    }}
                  >
                    <div className='icon'>
                      <img src={Compartilhamentos} alt='caret' />
                    </div>
                    <div className='option-name'>
                      Compartilhamentos
                    </div>
                  </div>
                  <div className='submenu-item'
                    onClick={() => {
                      window.open('/all', '_self');
                    }}
                  >
                    <div className='icon'>
                      <img src={Relatorios} alt='caret' />
                    </div>
                    <div className='option-name'>
                      Relatórios
                    </div>
                  </div>
                  {/* Add more submenu items as needed */}
                </div>
              )}
              <div className='menu-option'
                onClick={() => toggleMenu('Configurações')}
              >
                <div className='icon'>
                  <img src={Configuracoes} alt='caret' />
                </div>
                <div className='option-name'>
                  Configurações
                </div>
                <div className='arrow'>
                  <img src={openMenus['Configurações'] ? ArrowUp : ArrowDown} alt='Toggle Submenu' />
                </div>
              </div>
              {openMenus['Configurações'] && (
                <div className='submenu'>
                  <div className='submenu-item'
                    onClick={() => {
                      window.open('/appointment-types', '_self');
                    }}
                  >
                    <div className='icon'>
                      <img src={GerenciarTipos} alt='caret' />
                    </div>
                    <div className='option-name'>
                      Gerenciar tipos
                    </div>
                  </div>
                  <div className='submenu-item'
                    onClick={() => {
                      window.open('/configurations', '_self');
                    }}
                  >
                    <div className='icon'>
                      <img src={Notificacoes} alt='caret' />
                    </div>
                    <div className='option-name'>
                      Notificações
                    </div>
                  </div>
                  <div className='submenu-item'
                    onClick={() => {
                      window.open('/timezones', '_self');
                    }}
                  >
                    <div className='icon'>
                      <img src={FusoHorario} alt='caret' />
                    </div>
                    <div className='option-name'>
                      Fuso horário
                    </div>
                  </div>
                  {/* Add more submenu items as needed */}
                </div>
              )}
              <div className='menu-option'
                onClick={() => toggleMenu('Ajuda')}
              >
                <div className='icon'>
                  <img src={Ajuda} alt='caret' />
                </div>
                <div className='option-name'>
                  Ajuda
                </div>
                <div className='arrow'>
                  <img src={openMenus['Ajuda'] ? ArrowUp : ArrowDown} alt='Toggle Submenu' />
                </div>
              </div>
              {openMenus['Ajuda'] && (
                <div className='submenu'>
                  <div className='submenu-item'
                    onClick={() => {
                      window.open('https://www.youtube.com/channel/UCrriL5dwHWOSOs9ZpvJtgag', '_new');
                    }}
                  >
                    <div className='icon'>
                      <img src={Tutoriais} alt='caret' />
                    </div>
                    <div className='option-name'>
                      Tutoriais
                    </div>
                  </div>
                  <div className='submenu-item'
                    onClick={() => {
                      window.open('/talk-to-us', '_self');
                      console.log('/talk-to-us');
                    }}
                  >
                    <div className='icon'>
                      <img src={FaleConosco} alt='caret' />
                    </div>
                    <div className='option-name'>
                      Fale conosco
                    </div>
                  </div>
                </div>
              )}
              <div className='menu-option'
                onClick={(e) => {
                  window.open('/about', '_self');
                  // setIsVersionModalOpen(true);
                  // console.log('/talk-to-us');
                }}
              >
                <div className='icon'>
                  <img src={Sobre} alt='caret' />
                </div>
                <div className='option-name'>
                  Sobre
                </div>
              </div>
              <div className='menu-option version'
                onClick={(e) => {
                  setIsVersionModalOpen(true);
                }}
              >
                Versão {Config.TELEMBRO_VERSION}
              </div>
            </div>
            <div className='footer-container'>
              <img src={Logo} alt='logo' />
            </div>
            <div className='social-container'>
              <a href="https://www.instagram.com/telembro.app/" target="_blank" rel="noreferrer">
                <img src={Instagram} alt='caret' />
              </a>
              <a href="https://www.facebook.com/telembroapp" target="_blank" rel="noreferrer">
                <img src={Facebook} alt='caret' />
              </a>
              <a href="https://www.linkedin.com/company/telembro-app/" target="_blank" rel="noreferrer">
                <img src={Linkedin} alt='caret' />
              </a>
              <a href="https://www.youtube.com/channel/UCrriL5dwHWOSOs9ZpvJtgag" target="_blank" rel="noreferrer">
                <img src={Youtube} alt='caret' />
              </a>
            </div>
          </div>
          {/* <div className='links-container'>
            <>
              <a href="/">Versão {Config.TELEMBRO_VERSION}</a>
            </>
            {(showUserLink) && (
              <>
                <a href="/users/all">Ver usuários</a>
                <a href="/app-versions/">Versões do app</a>
              </>
            )}
            <a href="/">Compromissos</a>
            <a href="/shared">Compartilhamentos</a>
            <a href="/appointment-types">Gerenciar tipos</a>
            <a href="/all">Pesquisa/Relatório</a>
            <a href="https://www.youtube.com/channel/UCrriL5dwHWOSOs9ZpvJtgag" target="_blank" rel="noreferrer">Tutorial</a>
            <a href="/change-password">Trocar senha</a>
            <a href="/timezones">Fuso horário</a>
            <a href="/configurations">Push notifications</a>
            <a href="/talk-to-us">Fale Conosco</a>
            <a href="/about">Sobre</a>
            <button
              onClick={onLogOut}
            >
              Fazer logout
            </button>
            <button
              onClick={(e) => {
                onClose();
                onOpenModalCancelUserRegistration();
              }}
            >
              Cancelar cadastro
            </button>
            <div className='footer-container'>
              <img src={Logo} alt='caret' />
            </div>
          </div> */}
          {/* <div className='footer-container'>
            <img src={Logo} alt='caret' />
          </div> */}
        </div>
      </Overlay>

      <GenericModal
        title=''
        body={[
          <>
            <div className='center-image'>
              <img src={Version} alt='remove' />
            </div>
            <div className='modal-body-title'>
              Novidades da versão 8.0
            </div>
            <div className='modal-body-text'>
              Acabamos de atualizar o app TeLembro que contém os seguintes ajustes e funcionalidades:
              <br />
              <br />

              {'Implementação de "Push Notification": Agora você pode receber notificações no seu celular Habilite suas notificações em Menu > Configurações > Notificações.'}

              <br />
              <br />
              
              {'Convidar via QR-CODE: Este é um texto exemplo de uma modal. Use este espaço para fornecer informações importantes ou ações ao usuário.'}
            </div>

            <div className='button-container'>
              <button
                type="button"
                onClick={(e) => {
                  setIsVersionModalOpen(false);
                }}
              className="primary"
              >
                Ok, entendi
              </button>
            </div>
          </>
        ]}
        isVisible={isVersionModalOpen}
        // onConfirm={handleDuplicate}
        onCancel={(e) => {
          setIsVersionModalOpen(false);
        }}
      />
    </>,
    document.getElementById('sidebar-root')
  );
}

NewSideBar.propTypes = {
  onClose: PropTypes.func.isRequired,
  onLogOut: PropTypes.func.isRequired,
  showUserLink: PropTypes.bool.isRequired,
}

NewSideBar.defaultProps = {
  isVisible: true,
}